import React, { ReactNode, useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Box, Button, CircularProgress, TextField, Typography, Grid, Autocomplete, FormControl, Checkbox } from '@mui/material';
import { useAuth } from '../context/AuthContext';
import LayoutContainer from '../components/LayoutContainer';
import ReactMde from "react-mde";
import * as Showdown from "showdown";
import 'react-mde/lib/styles/css/react-mde-all.css';
import InfoBox from '../components/InfoBox';
import { Info } from '@mui/icons-material';
import KnowledgebaseTypeSelect from '../eve-components/KnowledgebaseTypeSelect';
import ContentClassificationSelect from '../eve-components/ContentClassificationSelect';

const PageCreateKnowledgebaseArticle: React.FC = () => {
    const { user } = useAuth();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const editMode = location.pathname.toLowerCase().startsWith('/learn/edit'); // Check if in edit mode
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);

    // State for storing the article ID (used in edit mode)
    const [articleId, setArticleId] = useState<string | null>(null);

    const [formData, setFormData] = useState({
        mainCategory: '',
        subCategory: '',
        articleCategory: '',
        title: '',
        content: '',
        type: 'CONCEPT', // Defaulting to 'CONCEPT'
        terms: '',
        background_image_url: '',
        thumb_url: '',
        icon_url: '',
        slug: '', // New field for slug
        content_classification: 'NONE',
        promoted: false,
    });

    const [categoryOptions, setCategoryOptions] = useState<string[]>([]);
    const [subCategoryOptions, setSubCategoryOptions] = useState<string[]>([]);
    const [articleCategoryOptions, setArticleCategoryOptions] = useState<string[]>([]);
    const [selectedTab, setSelectedTab] = useState<"write" | "preview">("write");

    // Markdown converter for preview
    const converter = new Showdown.Converter();

    // Fetch article data if in edit mode and set the article ID
    useEffect(() => {
        if (editMode) {
            const fetchArticle = async () => {
                try {
                    setLoading(true);
                    const response = await fetch(`/api/kb${location.pathname.toLowerCase().replace('/learn/edit', '')}`);
                    const article = await response.json();
                    setFormData({
                        mainCategory: article.main_category,
                        subCategory: article.sub_category,
                        articleCategory: article.article_category,
                        title: article.title,
                        content: article.content_md,
                        type: article.type,
                        terms: article.terms.join(', '),
                        background_image_url: article.background_image_url || '',
                        thumb_url: article.thumb_url || '',
                        icon_url: article.icon_url || '',
                        content_classification: article.content_classification,
                        promoted: article.promoted,
                        slug: article.slug // Populate slug if editing
                    });
                    setArticleId(article.id); // Store the article ID
                    setLoading(false);
                } catch (err) {
                    setError('Failed to fetch article data.');
                    setLoading(false);
                }
            };
            fetchArticle();
        }
    }, [editMode, searchParams]);

    // Fetch category, subcategory, and article category options from the server
    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const categoryRes = await fetch('/api/kb/categories'); // Replace with your API endpoint
                const categoryData = await categoryRes.json();
                setCategoryOptions(categoryData);

                const subCategoryRes = await fetch('/api/kb/subcategories'); // Replace with your API endpoint
                const subCategoryData = await subCategoryRes.json();
                setSubCategoryOptions(subCategoryData);

                const articleCategoryRes = await fetch('/api/kb/articlecategories'); // Replace with your API endpoint
                const articleCategoryData = await articleCategoryRes.json();
                setArticleCategoryOptions(articleCategoryData);
            } catch (err) {
                console.error('Failed to fetch categories:', err);
            }
        };

        fetchCategories();
    }, []);

    // Auto-generate slug based on the categories and title
    useEffect(() => {
        const generateSlug = () => {
            const formatPart = (part: string) =>
                part
                    .toLowerCase()
                    .replace(/[^a-z0-9]+/g, '-') // Replace non-alphanumeric characters with hyphens
                    .replace(/^-+|-+$/g, ''); // Remove leading and trailing hyphens

            const mainCategorySlug = formatPart(formData.mainCategory);
            const subCategorySlug = formData.subCategory ? formatPart(formData.subCategory) : '';
            const articleCategorySlug = formData.articleCategory ? formatPart(formData.articleCategory) : '';
            const titleSlug = formatPart(formData.title);

            const slug = `${mainCategorySlug}${subCategorySlug ? `/${subCategorySlug}` : ''}${articleCategorySlug ? `/${articleCategorySlug}` : ''}/${titleSlug}`;

            setFormData((prevData) => ({ ...prevData, slug }));
        };

        generateSlug();
    }, [formData.mainCategory, formData.subCategory, formData.articleCategory, formData.title]);

    // Handle form data changes
    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | { name?: string; value: unknown }>) => {
        const { name, value } = e.target as HTMLInputElement;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleMarkdownChange = (value: string) => {
        setFormData({
            ...formData,
            content: value
        });
    };

    const handleSubmit = async () => {
        if (!user) {
            navigate('/auth');
            return;
        }
        setLoading(true);
        setError(null);

        try {
            const method = editMode ? 'PUT' : 'POST';
            const url = editMode ? `/api/kb/${articleId}` : '/api/kb'; // Use articleId in edit mode

            const response = await fetch(url, {
                method,
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    ...formData,
                    terms: formData.terms.split(',').map(term => term.trim()), // Split terms by commas
                    created_by: user.username,
                    updated_by: user.username
                })
            });

            const result = await response.json();

            if (!response.ok) {
                setError(result.errors ? result.errors.map((e: any) => e.msg).join('\n\n') : `Failed to ${editMode ? 'update' : 'create'} article`);
                setLoading(false);
                return;
            }

            setLoading(false);
            navigate(`/learn${result.slug ?? result.updatedArticle?.slug}`); // Navigate to the newly created/updated article
        } catch (err) {
            console.error('Error submitting article:', err);
            setError(`Failed to ${editMode ? 'update' : 'create'} article. Please try again.`);
            setLoading(false);
        }
    };

    return (
        <Box component="section" sx={{ position: 'relative', mt: 8 }}>
            <LayoutContainer>
                <Typography variant="h4" sx={{ textAlign: 'center', mt: 4 }}>
                    {editMode ? 'Edit' : 'Create'} Knowledgebase Article
                </Typography>

                {!user && (
                    <InfoBox
                        icon={Info}
                        title="Notice"
                        text="You need to be logged in to create an article, please log in prior to submitting changes to continue without losing work."
                    />
                )}

                <Box sx={{ margin: '0 auto', mt: 4 }}>
                    {error && <Typography color="error">{error}</Typography>}

                    {/* Title and categories */}
                    <FormControl fullWidth margin="normal">
                        <TextField
                            label="Title"
                            name="title"
                            value={formData.title}
                            onChange={handleChange}
                            required
                        />
                    </FormControl>

                    {/* Combined row for categories with Autocomplete */}
                    <Grid container spacing={2} marginBottom={2}>
                        <Grid item xs={4}>
                            <Autocomplete
                                freeSolo
                                options={categoryOptions}
                                value={formData.mainCategory}
                                onInputChange={(event, newValue) => {
                                    setFormData({ ...formData, mainCategory: newValue });
                                }}
                                renderInput={(params) => (
                                    <TextField {...params} label="Main Category" required />
                                )}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <Autocomplete
                                freeSolo
                                options={subCategoryOptions}
                                value={formData.subCategory}
                                onInputChange={(event, newValue) => {
                                    setFormData({ ...formData, subCategory: newValue });
                                }}
                                renderInput={(params) => (
                                    <TextField {...params} label="Sub Category" />
                                )}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <Autocomplete
                                freeSolo
                                options={articleCategoryOptions}
                                value={formData.articleCategory}
                                onInputChange={(event, newValue) => {
                                    setFormData({ ...formData, articleCategory: newValue });
                                }}
                                renderInput={(params) => (
                                    <TextField {...params} label="Article Category" />
                                )}
                            />
                        </Grid>
                    </Grid>

                    <FormControl fullWidth margin="normal">
                        <ReactMde
                            value={formData.content}
                            onChange={handleMarkdownChange}
                            selectedTab={selectedTab}
                            onTabChange={setSelectedTab}
                            classes={{
                                reactMde: "react-mde", // Apply the custom dark mode class
                            }}
                            minEditorHeight={500}
                            generateMarkdownPreview={(markdown) => Promise.resolve(converter.makeHtml(markdown))}
                            childProps={{
                                writeButton: {
                                    tabIndex: -1
                                }
                            }}
                        />
                    </FormControl>

                    {/* Read-only Slug field */}
                    <FormControl fullWidth margin="normal">
                        <TextField
                            label="Slug"
                            name="slug"
                            value={formData.slug}
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                    </FormControl>

                    {/* Media fields */}
                    <Grid container spacing={2} marginBottom={2}>
                        <Grid item xs={4}>
                            <FormControl fullWidth margin="normal">
                                <TextField
                                    label="Background Image URL"
                                    name="background_image_url"
                                    value={formData.background_image_url}
                                    onChange={handleChange}
                                />
                            </FormControl>

                        </Grid>
                        <Grid item xs={4}>
                            <FormControl fullWidth margin="normal">
                                <TextField
                                    label="Thumbnail URL"
                                    name="thumb_url"
                                    value={formData.thumb_url}
                                    onChange={handleChange}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl fullWidth margin="normal">
                                <TextField
                                    label="Icon URL"
                                    name="icon_url"
                                    value={formData.icon_url}
                                    onChange={handleChange}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>

                    {/* Terms and Type */}
                    <FormControl fullWidth margin="normal">
                        <TextField
                            label="Terms (comma-separated)"
                            name="terms"
                            value={formData.terms}
                            onChange={handleChange}
                            required
                        />
                    </FormControl>

                    <FormControl fullWidth margin="normal">
                        <KnowledgebaseTypeSelect
                            value={formData.type}
                            onChange={handleChange as (event: any) => void}
                        />
                    </FormControl>

                    
                    <FormControl fullWidth margin="normal">
                        <ContentClassificationSelect
                            value={formData.content_classification}
                            onChange={handleChange as (event: any) => void}
                        />
                    </FormControl>

                    <FormControl fullWidth margin="normal">
                        <Checkbox
                            name="promoted"
                            value={formData.promoted}
                            onChange={handleChange}
                            required
                        />
                    </FormControl>

                    <Button
                        variant="contained"
                        color="primary"
                        sx={{ mt: 4 }}
                        onClick={handleSubmit}
                        disabled={loading}
                    >
                        {loading ? <CircularProgress size={24} /> : editMode ? 'Update Article' : 'Create Article'}
                    </Button>

                    {/* Live Markdown Preview */}
                    {selectedTab === "preview" && (
                        <Box sx={{ mt: 4 }}>
                            <Typography variant="h5">Preview</Typography>
                            <Box dangerouslySetInnerHTML={{ __html: converter.makeHtml(formData.content) }} />
                        </Box>
                    )}
                </Box>
            </LayoutContainer>
        </Box>
    );
};

export default PageCreateKnowledgebaseArticle;
