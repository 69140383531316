import React, { useState, useEffect } from 'react';
import { Box, Button, CircularProgress, Typography, Checkbox, FormControlLabel, Grid, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { useParams } from 'react-router-dom';
import LayoutContainer from '../components/LayoutContainer';

// Define the possible states for the creation process
enum EtymologyCreationState {
    PENDING = 'pending',
    SUCCESS = 'success',
    FAILED = 'failed',
    SKIPPED = 'skipped',
}

const PageSuggestEtymology: React.FC = () => {
    const { articleId } = useParams<{ articleId: string }>();
    const [loading, setLoading] = useState(false);
    const [suggestions, setSuggestions] = useState<any[]>([]);
    const [selectedSuggestions, setSelectedSuggestions] = useState<number[]>([]);
    const [error, setError] = useState<string | null>(null);
    const [etymologyStates, setEtymologyStates] = useState<Record<number, EtymologyCreationState>>({});
    const [articleContent, setArticleContent] = useState<string | null>(null);
    const [confirmUpdateDialog, setConfirmUpdateDialog] = useState(false);

    // Fetch suggestions and article content when the page loads
    useEffect(() => {
        const fetchSuggestionsAndArticle = async () => {
            setLoading(true);
            setError(null);

            try {
                // Fetch article content
                const articleRes = await fetch(`/api/kb/id/${articleId}`);
                const articleData = await articleRes.json();
                if (articleData.message && articleData.message.indexOf("error") >= 0) {
                    setError('Error trying to fetch the article.');
                    return;
                }
                setArticleContent(articleData.content_md);

                // Fetch etymology suggestions
                const response = await fetch(`/api/kb/etymologies/suggest/${articleId}`);
                const result = await response.json();
                if (response.ok) {
                    setSuggestions(result.suggestions);
                    setEtymologyStates(
                        result.suggestions.reduce(
                            (acc: Record<number, EtymologyCreationState>, _curr: any, index: number) => {
                                acc[index] = EtymologyCreationState.SKIPPED;
                                return acc;
                            },
                            {}
                        )
                    );
                } else {
                    setError(result.message);
                }
            } catch (err) {
                setError('Failed to fetch suggestions or article.');
            } finally {
                setLoading(false);
            }
        };

        fetchSuggestionsAndArticle();
    }, [articleId]);

    const handleCheckboxChange = (index: number) => {
        const newState = etymologyStates[index] === EtymologyCreationState.SKIPPED
            ? EtymologyCreationState.PENDING
            : EtymologyCreationState.SKIPPED;

        setEtymologyStates(prev => ({ ...prev, [index]: newState }));

        if (newState === EtymologyCreationState.PENDING) {
            setSelectedSuggestions((prev) => [...prev, index]);
        } else {
            setSelectedSuggestions((prev) => prev.filter((i) => i !== index));
        }
    };

    const handleSubmit = async () => {
        for (const index of selectedSuggestions) {
            const suggestion = suggestions[index];

            setEtymologyStates(prev => ({ ...prev, [index]: EtymologyCreationState.PENDING }));

            try {
                const response = await fetch('/api/kb/etymologies', {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({
                        name: suggestion.name,
                        short_description: suggestion.short_description,
                        icon_url: suggestion.icon_url,
                        image_url: suggestion.image_url,
                        category: suggestion.category,
                        related_item_id: suggestion.related_item_id,
                        knowledgebaseItemId: parseInt(articleId ?? '-1'),
                        ai_generated: true,
                    }),
                });

                if (response.ok) {
                    setEtymologyStates(prev => ({ ...prev, [index]: EtymologyCreationState.SUCCESS }));
                } else {
                    setEtymologyStates(prev => ({ ...prev, [index]: EtymologyCreationState.FAILED }));
                }
            } catch (err) {
                setEtymologyStates(prev => ({ ...prev, [index]: EtymologyCreationState.FAILED }));
            }
        }

        // Show dialog to ask the user if they want to update the source material with wrapped etymology terms
        setConfirmUpdateDialog(true);
    };

    // Update the article content by replacing each etymology with the wrapped equivalent (=name=)
    const handleUpdateArticle = async () => {
        if (articleContent) {
            let updatedContent = articleContent;

            suggestions.forEach((suggestion, index) => {
                if (etymologyStates[index] === EtymologyCreationState.SUCCESS) {
                    const regex = new RegExp(`\\b${suggestion.name}\\b`, 'gi');
                    updatedContent = updatedContent.replace(regex, `=${suggestion.name}=`);
                }
            });

            try {
                const response = await fetch(`/api/kb/${articleId}`, {
                    method: 'PUT',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ content: updatedContent }),
                });

                if (!response.ok) {
                    setError('Failed to update article content.');
                } else {
                    setConfirmUpdateDialog(false);
                }
            } catch (err) {
                setError('Failed to update article content.');
            }
        }
    };

    return (
        <Box sx={{ padding: 4, pt: 12 }}>
            <LayoutContainer>
                <Typography variant="h4">Suggested Etymologies for Article {articleId}</Typography>
                {error && <Typography color="error">{error}</Typography>}
                {loading ? (
                    <CircularProgress />
                ) : (
                    <>
                        <Grid container spacing={2} sx={{ mt: 4 }}>
                            {suggestions.map((suggestion, index) => {
                                const state = etymologyStates[index];
                                let backgroundColor;

                                switch (state) {
                                    case EtymologyCreationState.SUCCESS:
                                        backgroundColor = '#2c813e';
                                        break;
                                    case EtymologyCreationState.FAILED:
                                        backgroundColor = '#9d1515';
                                        break;
                                    case EtymologyCreationState.PENDING:
                                        backgroundColor = '#8b7738';
                                        break;
                                    default:
                                        backgroundColor = '#222222';
                                }

                                return (
                                    <Grid item xs={12} key={index}>
                                        <Box sx={{ backgroundColor, p: 2, borderRadius: '4px' }}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={state !== EtymologyCreationState.SKIPPED}
                                                        onChange={() => handleCheckboxChange(index)}
                                                        disabled={state === EtymologyCreationState.SUCCESS || loading}
                                                    />
                                                }
                                                label={
                                                    <Grid container alignItems="center">
                                                        <Grid item xs={1}>
                                                            <img src={suggestion.icon_url} style={{ width: 64 }} />
                                                        </Grid>
                                                        <Grid item xs={11}>
                                                            <Typography>
                                                                <strong>{suggestion.name}</strong> ({suggestion.category}): {suggestion.short_description}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                }
                                            />
                                        </Box>
                                    </Grid>
                                );
                            })}
                        </Grid>

                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleSubmit}
                            sx={{ mt: 4, width: 300 }}
                            disabled={loading}
                        >
                            {loading ? <CircularProgress size={24} /> : 'Accept Selected Suggestions'}
                        </Button>
                    </>
                )}
            </LayoutContainer>

            <Dialog
                open={confirmUpdateDialog}
                onClose={() => setConfirmUpdateDialog(false)}
            >
                <DialogTitle>Update Article Content</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Do you want to update the article content to include the new etymology terms, wrapping them with =name=?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setConfirmUpdateDialog(false)}>Cancel</Button>
                    <Button onClick={handleUpdateArticle} variant="contained" color="primary">
                        Yes, Update
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default PageSuggestEtymology;
