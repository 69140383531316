import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Box, Button, CircularProgress, Typography, Modal, Alert } from '@mui/material';
import LayoutContainer from '../components/LayoutContainer';

export async function getQuizSession(token: string) {
    return await fetch(`/api/quiz/session/${token}`);
}

export async function startQuizSession(token: string) {
    return await fetch(`/api/quiz/session/start/${token}`, {
        method: 'PUT',
    });
}

export async function getNextQuestion(token: string) {
    return await fetch(`/api/quiz/session/question/${token}`);
}

export async function submitQuizAnswer(token: string, questionId: number, answer: string) {
    return await fetch(`/api/quiz/session/answer/${token}`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ questionId, selectedAnswer: answer }),
    });
}

export async function completeQuiz(token: string,) {
    return await fetch(`/api/quiz/session/complete/${token}`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
    });
}


const PageQuizEngine: React.FC = () => {
    const { token } = useParams<{ token: string }>(); // Get token from URL
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [sessionData, setSessionData] = useState<any | null>(null);
    const [currentQuestion, setCurrentQuestion] = useState<any | null>(null);
    const [remainingTime, setRemainingTime] = useState<number>(0);
    const [answeredQuestions, setAnsweredQuestions] = useState<number>(0);

    const [quizInProgress, setQuizInProgress] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);

    const localStorageKey = `quiz_${token}_started`;

    if (!token) {
        return (
            <Box sx={{ textAlign: 'center', marginTop: '20vh' }}>
                <Typography color="error">A valid quiz token is required.</Typography>
            </Box>
        );
    }

    // Fetch the quiz session data using the token
    useEffect(() => {
        const fetchSessionData = async () => {
            setLoading(true);
            try {
                const response = await getQuizSession(token);
                
                // Check if the response is not ok and handle specific error cases
                if (!response.ok) {
                    const errorData = await response.json();
                    
                    // Handle the specific case where the session is not found or not in progress
                    if (errorData.message === "Quiz session not found or not in progress.") {
                        setError('Quiz session not found or it has not started yet.');
                        return;
                    }
                    
                    throw new Error('Failed to fetch quiz session');
                }
    
                const { session } = await response.json();
                setSessionData(session);
    
                // Check if the quiz is already in progress
                if (session.state === 'IN_PROGRESS' && !localStorage.getItem(localStorageKey)) {
                    setError('The quiz is already in progress. You must complete it.');
                } else if (session.state === 'PENDING') {
                    setModalOpen(true); // Show modal to start the quiz
                } else if (session.state === 'COMPLETED' || session.state === 'FAILED') {
                    setError('This quiz has already been completed.');
                } else {
                    setQuizInProgress(true);
                    startTimer(session.remaining_time); // Start the timer if in progress
                    fetchNextQuestion();
                }
            } catch (err) {
                setError((err as Error).message || 'Unknown error occurred');
            } finally {
                setLoading(false);
            }
        };
    
        fetchSessionData();
    }, [token]);
    

    // Start the quiz and set the localStorage item
    const startQuiz = async () => {
        try {
            const response = await startQuizSession(token); // API to start the quiz
            if (!response.ok) throw new Error('Failed to start the quiz');
            const data = await response.json();
            setQuizInProgress(true);
            localStorage.setItem(localStorageKey, 'true'); // Store quiz start in local storage
            setSessionData(data);
            setModalOpen(false);
            startTimer(data.time_limit);
            fetchNextQuestion();
        } catch (err) {
            setError((err as Error).message || 'Failed to start the quiz');
        }
    };

    // Timer logic
    const startTimer = (seconds: number) => {
        setRemainingTime(seconds);
        const interval = setInterval(() => {
            setRemainingTime((prevTime) => {
                if (prevTime <= 1) {
                    clearInterval(interval);
                    return 0;
                }
                return prevTime - 1;
            });
        }, 1000);
    };

    // Fetch the next question in the quiz
    const fetchNextQuestion = async () => {
        try {
            const response = await getNextQuestion(token);
            
            // Check if response is not ok (e.g., a 400 response)
            if (!response.ok) {
                const errorData = await response.json();
                
                // If the response contains "No more questions.", complete the quiz
                if (errorData.message === "No more questions.") {
                    await completeQuiz(token);  // Mark quiz as complete
                    navigate(`/quiz/results/${token}`);  // Redirect to results page
                    return;
                }
    
                throw new Error('Failed to fetch the next question');
            }
    
            const data = await response.json();
            setCurrentQuestion(data.question);  // Set the next question
        } catch (err) {
            setError((err as Error).message || 'Failed to fetch the next question');
        }
    };
    

    // Submit the answer to the current question
    const submitAnswer = async (answer: string) => {
        try {
            const response = await submitQuizAnswer(token, currentQuestion.id, answer);
            if (!response.ok) throw new Error('Failed to submit answer');
            setAnsweredQuestions(answeredQuestions + 1);
            fetchNextQuestion(); // Fetch the next question
        } catch (err) {
            setError((err as Error).message || 'Failed to submit the answer');
        }
    };

    if (loading) {
        return (
            <Box sx={{ textAlign: 'center', marginTop: '20vh' }}>
                <CircularProgress />
            </Box>
        );
    }

    if (error) {
        return (
            <Box sx={{ textAlign: 'center', marginTop: '20vh' }}>
                <Typography color="error">{error}</Typography>
            </Box>
        );
    }

    // Render the quiz modal if the quiz is pending
    return (
        <LayoutContainer>
            {!quizInProgress && <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', backgroundColor: '#222', p: 4, boxShadow: 24, borderRadius: '8px' }}>
                <Typography variant="h6" gutterBottom>
                    You are about to start a quiz!
                </Typography>
                <Typography variant="body1" sx={{ mb: 2 }}>
                    Topics covered: {sessionData?.topics?.join(', ')} <br />
                    Difficulty: {sessionData?.difficulty} <br />
                    Time limit: {sessionData?.time_limit} seconds
                </Typography>
                <Alert severity="info">Once you begin the test, you must complete it within the specified time period to be graded.</Alert>
                <Box sx={{ mt: 2, textAlign: 'center' }}>
                    <Button variant="contained" color="primary" onClick={startQuiz}>Start Quiz</Button>
                </Box>
            </Box>}


            {quizInProgress && (
                <Box sx={{ padding: '32px', pt: 10 }}>
                    {/* Timer and Progress */}
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                        <Typography variant="h6">Remaining Time: {remainingTime} seconds</Typography>
                        <Typography variant="h6">Answered: {answeredQuestions}/{sessionData?.questions?.length}</Typography>
                    </Box>

                    {/* Quiz Question */}
                    {currentQuestion && (
                        <Box sx={{ textAlign: 'center', mt: 4 }}>
                            <Typography variant="h5" sx={{ mb: 2 }}>{currentQuestion.question}</Typography>
                            {currentQuestion.image_url && (
                                <Box component="img" src={currentQuestion.image_url} sx={{ mb: 2, maxWidth: '100%', maxHeight: '300px' }} />
                            )}
                            {/* Answer Options */}
                            {['A', 'B', 'C', 'D'].map((option, index) => {
                                return <Button
                                    key={index}
                                    variant="contained"
                                    sx={{ display: 'block', width: '100%', mb: 2 }}
                                    onClick={() => submitAnswer(option)}
                                >
                                    {`${option}: ${currentQuestion.answers[index]}`}
                                </Button>;
                            }

                            )}
                        </Box>
                    )}
                </Box>
            )}
        </LayoutContainer>
    );
};

export default PageQuizEngine;
