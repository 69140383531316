import React, { useState, useEffect } from 'react';
import { Box, Typography, Grid, TextField, CircularProgress } from '@mui/material';
import KnowledgebaseArticleCard from '../eve-components/KnowledgebaseArticleCard';
import LayoutContainer from '../components/LayoutContainer';
import ThemeBackground from '../components/ThemeBackground';
import { ProfileTheme } from '../types/ProfileTheme';
import CategoryIcon from '../eve-components/CategoryIcon';
import InfoBox from '../components/InfoBox';
import { Info } from '@mui/icons-material';

const theme: ProfileTheme = {
    avatarUrl: '/assets/avatar_example.png',
    backgroundUrl: '/assets/theme/lost/background.jpg',
    overlayUrls: ['/assets/theme/lost/overlay1.jpg'],
    horizontalRuleUrl: '/assets/theme/lost/seperator.jpg',
    backgroundColor: '#110f0d',
    overlayStyles: [{
        mixBlendMode: 'screen',
        filter: 'grayscale(1)',
        animation: 'parallax 92s linear infinite',
        opacity: 0.4,
    }],
    fontStyles: {
        title: { fontFamily: '"Jersey 20"', fontSize: '80px', lineHeight: '80px', fontWeight: 400, fontStyle: 'normal', color: '#fff' },
        subheading: { fontFamily: '"Jersey 10"', fontSize: '40px', fontWeight: 400, lineHeight: '48px', fontStyle: 'normal', color: '#fff' },
        text: { fontFamily: '"Jersey 10"', fontSize: '24px', fontWeight: 400, lineHeight: '30px', fontStyle: 'normal', color: '#fff' },
        para: { fontFamily: '"DM Sans"', fontSize: '16px', color: '#999' }
    },
    tabStyle: {
        backgroundColor: 'transparent', color: '#fff', padding: '10px 20px',
    },
    containerStyle: { backgroundColor: '#1b1c1e' },
    globalStyles: {
    },
};

const PageKnowledgebaseHome: React.FC = () => {
    const [searchQuery, setSearchQuery] = useState<string>('');
    const [debouncedSearchQuery, setDebouncedSearchQuery] = useState<string>(searchQuery);
    const [searchResults, setSearchResults] = useState<any[]>([]);
    const [beginnerGuides, setBeginnerGuides] = useState<any[]>([]);
    const [mostViewedArticles, setMostViewedArticles] = useState<any[]>([]);
    const [mainCategories, setMainCategories] = useState<any[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [searchLoading, setSearchLoading] = useState<boolean>(false);

    useEffect(() => {
        // Debounce the search query update
        const handler = setTimeout(() => {
            setDebouncedSearchQuery(searchQuery); // Update the debounced query after the timeout
        }, 500); // 500ms delay

        // Cleanup the timeout if the query changes within the delay
        return () => {
            clearTimeout(handler);
        };
    }, [searchQuery]);

    useEffect(() => {
        // Fetch articles only when debouncedSearchQuery changes
        const fetchSearchResults = async () => {
            if (debouncedSearchQuery.length === 0) {
                setSearchResults([]);
                return;
            }

            setSearchLoading(true);
            try {
                const response = await fetch(`/api/kb/search?query=${debouncedSearchQuery}`);
                const data = await response.json();
                setSearchResults(data); // Update search results
            } catch (err) {
                console.error('Error fetching search results:', err);
            }
            setSearchLoading(false);
        };

        fetchSearchResults();
    }, [debouncedSearchQuery]);

    useEffect(() => {
        // Fetch beginner guides
        const fetchBeginnerGuides = async () => {
            try {
                const response = await fetch(`/api/kb/articles/beginner`);
                const data = await response.json();
                setBeginnerGuides(data.slice(0, 3)); // Limit to 3 articles
            } catch (err) {
                console.error('Error fetching beginner guides:', err);
            }
        };

        // Fetch most viewed articles
        const fetchMostViewedArticles = async () => {
            try {
                const response = await fetch(`/api/kb/articles/most-viewed`);
                const data = await response.json();
                setMostViewedArticles(data.slice(0, 3)); // Limit to 3 articles
            } catch (err) {
                console.error('Error fetching most viewed articles:', err);
            }
        };

        const fetchMainCategories = async () => {
            try {
                const response = await fetch(`/api/kb/categories/main`);
                const data = await response.json();
                setMainCategories(data);
            } catch (err) {
                console.error('Error fetching main categories:', err);
            }
        };

        setLoading(true);
        fetchBeginnerGuides();
        fetchMostViewedArticles();
        fetchMainCategories();
        setLoading(false);
    }, []);

    // Handle the search query and fetch results as the user types
    useEffect(() => {
        const fetchSearchResults = async () => {
            if (searchQuery.length === 0) {
                setSearchResults([]);
                return;
            }

            setSearchLoading(true);
            try {
                const response = await fetch(`/api/kb/search?query=${searchQuery}`);
                const data = await response.json();
                setSearchResults(data); // Update search results
            } catch (err) {
                console.error('Error fetching search results:', err);
            }
            setSearchLoading(false);
        };

        const delayDebounceFn = setTimeout(() => {
            fetchSearchResults();
        }, 500); // Debounce search requests to avoid excessive API calls

        return () => clearTimeout(delayDebounceFn); // Cleanup debounce on unmount or input change
    }, [searchQuery]);

    if (loading) {
        return (
            <Box sx={{ textAlign: 'center', marginTop: '20vh' }}>
                <CircularProgress />
            </Box>
        );
    }

    return (
        <Box component="section" sx={{ background: theme.backgroundColor, color: theme.fontStyles.text.color, position: 'relative', minHeight: '100vh' }}>
            <ThemeBackground theme={theme} />

            <Box component="div" style={{ position: 'absolute', left: 0, right: 0, top: 0, height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.1)' }}>

</Box>
            <LayoutContainer>

                <Box sx={{ position: 'relative', padding: 5, pb: 10,}}>
                    <Box component="div" style={{ position: 'absolute', left: -60, right: -20, top: 400, height: 300, background: `linear-gradient(0deg, rgba(0,0,0,0.2) 0%, transparent 100%)` }}>

                    </Box>
                    <Box component="div" style={{ position: 'absolute', left: -60, right: -20, top: 700, bottom: 0, backgroundColor: 'rgba(0, 0, 0, 0.2)' }}>

                    </Box>
                    {/* Hero Section */}
                    <Box sx={{ textAlign: 'center', padding: '64px 16px', borderRadius: '8px', position: 'relative', textShadow: '0px 2px 2px #000' }}>
                        <Typography variant="h2" sx={{ mb: 2, pt: 8, }}>
                            Welcome to EVE Learn
                        </Typography>
                        <Typography variant="h6" sx={{ mb: 4 }}>
                            Explore comprehensive guides, tips, and tricks for EVE Online.
                        </Typography>

                        {/* Search Box */}
                        <Box sx={{ display: 'flex', justifyContent: 'center', mb: 4 }}>
                            <TextField
                                variant="outlined"
                                placeholder="Search for articles..."
                                value={searchQuery}
                                onChange={(e) => {
                                    setSearchLoading(true);
                                    setSearchQuery(e.target.value)
                                }}
                                sx={{ width: '50%', borderRadius: '8px', backgroundColor: 'rgba(0,0,0,0.7)', backdropFilter: 'blur(4px)' }}
                            />
                        </Box>

                        <Typography variant="body1">
                            Our knowledgebase aims to cover everything from beginner guides to advanced tactics and strategies.
                        </Typography>
                    </Box>

                    {/* Search Results Section */}
                    {searchLoading ? (
                        <Box sx={{ textAlign: 'center', marginTop: '4vh', position: 'relative' }}>
                            <CircularProgress />
                        </Box>
                    ) : searchResults.length > 0 && (
                        <Box sx={{ mt: 8, position: 'relative' }}>
                            <Typography variant="h4" sx={{ mb: 4 }}>
                                Search Results
                            </Typography>
                            <Grid container spacing={4}>
                                {searchResults.map((article) => (
                                    <Grid item xs={12} md={4} key={article.slug}>
                                        <KnowledgebaseArticleCard
                                            title={article.title}
                                            content_synopsis={article.content_synopsis}
                                            thumb_url={article.thumb_url}
                                            main_category={article.main_category}
                                            sub_category={article.sub_category}
                                            article_category={article.article_category}
                                            updated_at={article.updated_at}
                                            content_classification={article.content_classification}
                                            slug={article.slug} // Passing slug for navigation
                                        />
                                    </Grid>
                                ))}
                            </Grid>
                        </Box>
                    )}

                    {/* Beginner Guides Section */}
                    <Box sx={{ mt: 8, position: 'relative' }}>
                        <Typography variant="h4" sx={{ mb: 4 }}>
                            Beginner Guides
                        </Typography>
                        <Grid container spacing={4}>
                            {beginnerGuides.map((article) => (
                                <Grid item xs={12} md={4} key={article.slug}>
                                    <KnowledgebaseArticleCard
                                        title={article.title}
                                        content_synopsis={article.content_synopsis}
                                        thumb_url={article.thumb_url}
                                        main_category={article.main_category}
                                        sub_category={article.sub_category}
                                        article_category={article.article_category}
                                        updated_at={article.updated_at}
                                        content_classification={article.content_classification}
                                        slug={article.slug} // Passing slug for navigation
                                    />
                                </Grid>
                            ))}
                        </Grid>
                    </Box>

                    {/* Most Viewed Articles Section */}
                    <Box sx={{ mt: 8, position: 'relative' }}>
                        <Typography variant="h4" sx={{ mb: 4 }}>
                            Most Viewed Articles
                        </Typography>
                        <Grid container spacing={4}>
                            {mostViewedArticles.map((article) => (
                                <Grid item xs={12} md={4} key={article.slug}>
                                    <KnowledgebaseArticleCard
                                        title={article.title}
                                        content_synopsis={article.content_synopsis}
                                        thumb_url={article.thumb_url}
                                        main_category={article.main_category}
                                        sub_category={article.sub_category}
                                        article_category={article.article_category}
                                        updated_at={article.updated_at}
                                        content_classification={article.content_classification}
                                        slug={article.slug} // Passing slug for navigation
                                    />
                                </Grid>
                            ))}
                        </Grid>
                    </Box>

                    {/* Main Categories Section */}
                <Box sx={{ mt: 8, position: 'relative' }}>
                    <Typography variant="h4" sx={{ mb: 4 }}>
                        Browse by Categories
                    </Typography>
                    <Grid container spacing={4}>
                        {mainCategories.map((category) => (
                            <Grid item xs={6} md={3} key={category}>
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, zoom: 1.25, }}>
                                    <CategoryIcon category={category} />
                                    {/* <Typography variant="body1" sx={{ color: '#fff' }}>
                                        {category}
                                    </Typography> */}
                                </Box>
                            </Grid>
                        ))}
                    </Grid>
                </Box>
                <Box sx={{ position: 'relative'}}>
                <InfoBox
                    icon={Info}
                    title="Under Construction"
                    text="This knowledgebase is still in early stages and many articles are not yet complete. If you have any suggestions please reach out on discord!"
                    backgroundColor="#6a5487" // Custom background color
                />
                </Box>
                </Box>
            </LayoutContainer>
        </Box>
    );
};

export default PageKnowledgebaseHome;
