import React, { useState } from 'react';
import { Box, Button, CircularProgress, TextField, Typography, Checkbox, FormControl, FormControlLabel, MenuItem, Select, InputLabel, SelectChangeEvent, Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import LayoutContainer from '../components/LayoutContainer';

const quizLevels = ['NOVICE', 'BEGINNER', 'INTERMEDIATE', 'EXPERT'];
const quizTopics = [
    'INDUSTRY', 'COMBAT', 'LORE', 'UI', 'POLITICS', 'ECONOMY', 'EXPLORATION',
    'TRADE', 'PILOTING', 'NAVIGATION', 'SAVING', 'FLEET_MANAGEMENT', 'SALVAGING'
];

const PageCreateQuizSession: React.FC = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    const [formData, setFormData] = useState({
        in_game_name: '',
        difficulty: 'NOVICE',
        topics: quizTopics, // Pre-check all topics by default
        desired_question_count: 10,
        time_limit: 300, // Time limit in seconds (5 minutes)
    });

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSelectChange = (e: SelectChangeEvent) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name as string]: value
        });
    };

    const handleCheckboxChange = (topic: string) => {
        const topics = formData.topics.includes(topic)
            ? formData.topics.filter(t => t !== topic)
            : [...formData.topics, topic];
        setFormData({ ...formData, topics });
    };

    const handleSubmit = async () => {
        setLoading(true);
        setError(null);

        try {
            const response = await fetch('/api/quiz/session', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(formData),
            });

            if (!response.ok) {
                const result = await response.json();
                throw new Error(result.message || 'Failed to create quiz session');
            }

            const result = await response.json();
            setLoading(false);
            navigate(`/quiz/${result.session.token}`); // Navigate to the session page
        } catch (err: any) {
            setError(err.message ?? 'Unknown error');
            setLoading(false);
        }
    };

    return (
        <LayoutContainer>
            <Box sx={{ padding: '32px', pt: 10 }}>
                <Typography variant="h4" sx={{ mb: 4 }}>
                    Create New Quiz Session
                </Typography>

                {error && <Typography color="error">{error}</Typography>}

                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
                    <Grid container spacing={2}>
                        {/* In-Game Name */}
                        <Grid item xs={12} md={6}>
                            <TextField
                                label="In-Game Name"
                                name="in_game_name"
                                value={formData.in_game_name}
                                onChange={handleInputChange}
                                fullWidth
                                required
                            />
                        </Grid>

                        {/* Difficulty Select */}
                        <Grid item xs={12} md={6}>
                            <FormControl fullWidth>
                                <InputLabel>Difficulty</InputLabel>
                                <Select
                                    name="difficulty"
                                    value={formData.difficulty}
                                    onChange={handleSelectChange}
                                >
                                    {quizLevels.map(level => (
                                        <MenuItem key={level} value={level}>
                                            {level.charAt(0) + level.slice(1).toLowerCase()}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>

                        {/* Desired Question Count */}
                        <Grid item xs={12} md={6}>
                            <TextField
                                label="Desired Question Count"
                                name="desired_question_count"
                                type="number"
                                value={formData.desired_question_count}
                                onChange={handleInputChange}
                                fullWidth
                                required
                            />
                        </Grid>

                        {/* Time Limit */}
                        <Grid item xs={12} md={6}>
                            <TextField
                                label="Time Limit (in seconds)"
                                name="time_limit"
                                type="number"
                                value={formData.time_limit}
                                onChange={handleInputChange}
                                fullWidth
                                required
                            />
                        </Grid>

                        {/* Topic Checkboxes */}
                        <Grid item xs={12}>
                            <Typography variant="h6" sx={{ mb: 2 }}>
                                Topics
                            </Typography>
                            <Grid container spacing={2}>
                                {quizTopics.map(topic => (
                                    <Grid item xs={6} sm={4} key={topic}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={formData.topics.includes(topic)}
                                                    onChange={() => handleCheckboxChange(topic)}
                                                />
                                            }
                                            label={topic
                                                .charAt(0) +
                                                topic
                                                    .slice(1)
                                                    .toLowerCase()
                                                    .replace('_', ' ')}
                                        />
                                    </Grid>
                                ))}
                            </Grid>
                        </Grid>
                    </Grid>

                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSubmit}
                        disabled={loading}
                    >
                        {loading ? <CircularProgress size={24} /> : 'Create Quiz Session'}
                    </Button>
                </Box>
            </Box>
        </LayoutContainer>
    );
};

export default PageCreateQuizSession;
