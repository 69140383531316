import React, { ReactNode } from 'react';
import { FormControl, InputLabel, Select, MenuItem, SelectChangeEvent, ListItemIcon, ListItemText, Box } from '@mui/material';
import { School, Lightbulb, Build, Work, RateReview, MoreHoriz } from '@mui/icons-material'; // Icons from MUI

// Define an object that maps each ContentClassification to an icon
const classificationIcons: { [key: string]: ReactNode } = {
    BEGINNER: <School />,
    INTERMEDIATE: <Lightbulb />,
    ADVANCED: <Build />,
    OPINION: <RateReview />,
    WORK_IN_PROGRESS: <Work />,
    OTHER: <MoreHoriz />,
};

const contentClassifications = [
    'BEGINNER', 'INTERMEDIATE', 'ADVANCED', 'OPINION', 'WORK_IN_PROGRESS', 'OTHER'
];

const ContentClassificationSelect: React.FC<{ value: string, onChange: (event: SelectChangeEvent<string>) => void }> = ({ value, onChange }) => {

    return (
        <FormControl fullWidth margin="normal">
            <InputLabel>Content Classification</InputLabel>
            <Select
                name="content_classification"
                value={value}
                onChange={onChange}
                renderValue={(selected) => (
                    <Box sx={{ display: 'flex', alignItems: 'center', color: 'white' }}> {/* Ensure white text and icon */} 
                        <ListItemIcon sx={{ color: 'white' }}> {/* Set icon color to white */}
                            {classificationIcons[selected as string] || <MoreHoriz />}
                        </ListItemIcon>
                        <ListItemText
                            primary={selected?.toString().replace('_', ' ')}
                            primaryTypographyProps={{ color: 'white' }} // Ensure text color is white
                        />
                    </Box>
                )}
                sx={{ color: 'white' }} // Ensure dropdown itself has white text
            >
                {contentClassifications.map((classification) => (
                    <MenuItem key={classification} value={classification}>
                        <ListItemIcon>
                            {classificationIcons[classification] || <MoreHoriz />}
                        </ListItemIcon>
                        <ListItemText primary={classification.replace('_', ' ')} />
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export default ContentClassificationSelect;
