import React from 'react';
import { Box, Tooltip, IconButton, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { getCategoryIcon } from '../utils/CategoryIconMapper';

type CategoryIconProps = {
    category: string;
    nohover?: boolean;  // Optional prop to disable the tooltip
};

const CategoryIcon: React.FC<CategoryIconProps> = ({ category, nohover }) => {
    const navigate = useNavigate();
    const iconUrl = getCategoryIcon(category);

    const handleClick = () => {
        navigate(`/learn/categories/${category.toLowerCase()}`);
    };

    const iconContent = (
        <IconButton onClick={handleClick} sx={{ display: 'flex', alignItems: 'center', padding: 0 }}>
            {/* Icon */}
            <Box
                component="img"
                src={iconUrl}
                alt={category}
                sx={{
                    width: 32,  // Adjusted size for better alignment with text
                    height: 32,
                    borderRadius: '50%',
                    marginRight: 1,  // Space between icon and text
                    backgroundColor: 'transparent',
                    '&:hover': {
                        opacity: 0.8,
                    },
                }}
            />
            {/* Category text */}
            <Typography variant="body1" sx={{ color: '#fff' }}>
                {category}
            </Typography>
        </IconButton>
    );

    // Conditionally render the tooltip based on the nohover prop
    return nohover ? iconContent : (
        <Tooltip title={`Find more ${category}`}>
            {iconContent}
        </Tooltip>
    );
};

export default CategoryIcon;
