import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Box, Typography, TextField, Checkbox, Button, FormControlLabel, Grid } from '@mui/material';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import StarHalfIcon from '@mui/icons-material/StarHalf';
import StarIcon from '@mui/icons-material/Star';
import MilitaryTechIcon from '@mui/icons-material/MilitaryTech'
import LayoutContainer from '../components/LayoutContainer';

// Define background and colors for each experience level
const experienceBackgrounds = {
    NOVICE: {
        background: "https://cdn.everbloom.cloud/eve/promo/modes_novice.jpg",
        color: '#3d5763',
    },
    BEGINNER: {
        background: "https://cdn.everbloom.cloud/eve/promo/modes_beginner.jpg",
        color: '#2e5137',
    },
    INTERMEDIATE: {
        background: "https://cdn.everbloom.cloud/eve/promo/modes_intermediate.jpg",
        color: '#6d5a37',
    },
    VETERAN: {
        background: "https://cdn.everbloom.cloud/eve/promo/modes_expert.jpg",
        color: '#6d375c',
    },
};

// Styled component for the entire section
const Section = styled.section`
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background 0.3s ease-in-out;
`;

const FormContainer = styled(Box)`
  background-color: rgba(0, 0, 0, 0.7);
  padding: 40px;
  border-radius: 8px;
  max-width: 500px;
  width: 100%;
  color: #fff;
`;

const ExperienceSlider = styled.input`
  width: 100%;
  margin: 20px 0;
`;

const PlayerExperienceForm: React.FC = () => {
    const [playerName, setPlayerName] = useState('');
    const [discordUsername, setDiscordUsername] = useState('');
    const [experienceLevel, setExperienceLevel] = useState<'NOVICE' | 'BEGINNER' | 'INTERMEDIATE' | 'VETERAN'>('NOVICE');
    const [discordRequired, setDiscordRequired] = useState(false);
    const [interviewRequired, setInterviewRequired] = useState(false);
    const [loading, setLoading] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [formValid, setFormValid] = useState(false);

    useEffect(() => {
        if (!playerName || !discordUsername || !discordRequired || !interviewRequired) {
            setFormValid(false);
            return;
        }
        setFormValid(true);
    }, [playerName, discordRequired, discordRequired, interviewRequired]);

    // Handle background changes based on experience level
    const backgroundConfig = experienceBackgrounds[experienceLevel];

    const handleSubmit = async () => {
        const applicationData = {
            playerName,
            discordUsername,
            experienceLevel,
            discordRequired,
            interviewRequired,
        };
        setLoading(true);
        debugger;
        // Submit the application
        try {
            await fetch('/api/members/applications', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(applicationData),
            });
            setTimeout(() => {
                setLoading(false);
                setSubmitted(true);
            }, 500);
        } catch (e: any) {
            setLoading(false);
            setSubmitted(false);
            return;
        }

    };


    return (
        <section style={{ position: 'relative', marginBottom: 12 }}>
            <Box sx={{ position: 'relative', opacity: loading ? 0.7 : 1 }}>

                <Box sx={{ position: 'absolute', left: 0, right: 0, top: 0, bottom: 0, overflow: 'hidden', opacity: 0.6 }}>
                    <div style={{ position: 'absolute', left: 0, right: 0, top: 0, bottom: 0, transition: 'all 0.7s', backgroundImage: `url('${backgroundConfig.background}')`, backgroundSize: 'cover', backgroundPosition: 'center' }}>

                    </div>
                </Box>

                <Box sx={{ position: 'absolute', left: 0, right: 0, top: 0, bottom: 0, background: 'linear-gradient(0deg, rgba(17,15,18,1) 5%, rgba(17,15,18,0.3) 30%, rgba(17,15,18,0.6) 60%, rgba(17,15,18,1) 100%);' }}>

                </Box>
                <LayoutContainer>
                    <Box p={4} sx={{ position: 'relative', filter: 'drop-shadow(0px 4px 10px rgba(0, 0,0 , 0.8))', }}>
                        <Typography variant="h4" p={3} align="center" gutterBottom>
                            {submitted ? 'Application Submitted' : 'Apply To Join'}
                        </Typography>

                        {submitted && <Typography variant="body1" sx={{ textAlign: 'center', pb: 4 }}>
                            Thank you for your application. We will get in touch with you soon.
                        </Typography>}

                        {!submitted && <Grid container spacing={4}>

                            <Grid item xs={12} md={6}>
                                <Grid container spacing={2}>

                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            fullWidth
                                            variant="outlined"
                                            label="In-Game Player Name"
                                            value={playerName}
                                            onChange={(e) => setPlayerName(e.target.value)}
                                            sx={{ mb: 2, backdropFilter: 'blur(3px)', backgroundColor: 'rgba(55, 55, 55, 0.3)' }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            fullWidth
                                            variant="outlined"
                                            label="Discord Username"
                                            value={discordUsername}
                                            onChange={(e) => setDiscordUsername(e.target.value)}
                                            sx={{ mb: 2, backdropFilter: 'blur(3px)', backgroundColor: 'rgba(55, 55, 55, 0.3)' }}
                                        />

                                    </Grid>
                                </Grid>


                                {/* Experience Slider */}
                                <Typography variant="h6">Player Experience: {experienceLevel}</Typography>
                                <ExperienceSlider
                                    type="range"
                                    min="0"
                                    max="3"
                                    step="1"
                                    value={['NOVICE', 'BEGINNER', 'INTERMEDIATE', 'VETERAN'].indexOf(experienceLevel)}
                                    onChange={(e) => {
                                        const level = ['NOVICE', 'BEGINNER', 'INTERMEDIATE', 'VETERAN'][parseInt(e.target.value)] as typeof experienceLevel;
                                        setExperienceLevel(level);
                                    }}
                                    color={experienceBackgrounds[experienceLevel].color}
                                />
                                {/* Discord Required Checkbox */}
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            sx={{ color: '#fff' }}
                                            checked={discordRequired}
                                            onChange={() => setDiscordRequired(!discordRequired)}
                                        />
                                    }
                                    label="I understand that Discord is a requirement."
                                />

                                {/* Interview Required Checkbox */}
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            sx={{ color: '#fff' }}
                                            checked={interviewRequired}
                                            onChange={() => setInterviewRequired(!interviewRequired)}
                                        />
                                    }
                                    label="I understand that a short interview is required."
                                />

                                <Button disabled={loading || !formValid} variant="contained" fullWidth onClick={handleSubmit} sx={{ mt: 2, width: '100%', backgroundColor: experienceBackgrounds[experienceLevel].color, '&:disabled': { opacity: 0.5, backgroundColor: experienceBackgrounds[experienceLevel].color, color: '#888' } }}>
                                    Submit Application
                                </Button>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Box
                                    component={"div"}
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        height: '100%', // Full height of the column
                                        textAlign: 'center',
                                        textShadow: '0px 2px 2px #000, 0px 5px 15px #000',
                                    }}
                                >
                                    <Typography variant="h4">
                                        {experienceLevel === 'NOVICE' && "Novice"}
                                        {experienceLevel === 'BEGINNER' && "Beginner"}
                                        {experienceLevel === 'INTERMEDIATE' && "Intermediate"}
                                        {experienceLevel === 'VETERAN' && "Veteran"}
                                    </Typography>
                                    <Typography variant="body2" mb={1} mt={1}>
                                        {experienceLevel === 'NOVICE' && "You've probably been playing for days"}
                                        {experienceLevel === 'BEGINNER' && "You've probably been playing for weeks"}
                                        {experienceLevel === 'INTERMEDIATE' && "You've probably been playing for months"}
                                        {experienceLevel === 'VETERAN' && "You've probably been playing for years"}
                                    </Typography>
                                    <Typography variant="body1" fontSize={18} sx={{ textAlign: 'center' }} p={2} pr={4}>
                                        <Box display="flex" flexDirection="column">
                                            {experienceLevel === 'NOVICE' && (
                                                <>
                                                    <Box display="flex" alignItems="center" mb={1}>
                                                        <StarOutlineIcon color="primary" sx={{ mr: 1 }} />
                                                        <Typography>Brand new to the game</Typography>
                                                    </Box>
                                                    <Box display="flex" alignItems="center" mb={1}>
                                                        <StarOutlineIcon color="primary" sx={{ mr: 1 }} />
                                                        <Typography>No prior experience with player combat or industry</Typography>
                                                    </Box>
                                                    <Box display="flex" alignItems="center" mb={1}>
                                                        <StarOutlineIcon color="primary" sx={{ mr: 1 }} />
                                                        <Typography>Still learning basic controls and game mechanics</Typography>
                                                    </Box>
                                                </>
                                            )}
                                            {experienceLevel === 'BEGINNER' && (
                                                <>
                                                    <Box display="flex" alignItems="center" mb={1}>
                                                        <StarHalfIcon color="primary" sx={{ mr: 1 }} />
                                                        <Typography>Limited combat or industry experience</Typography>
                                                    </Box>
                                                    <Box display="flex" alignItems="center" mb={1}>
                                                        <StarHalfIcon color="primary" sx={{ mr: 1 }} />
                                                        <Typography>Familiar with high-sec space and safe activities</Typography>
                                                    </Box>
                                                    <Box display="flex" alignItems="center" mb={1}>
                                                        <StarHalfIcon color="primary" sx={{ mr: 1 }} />
                                                        <Typography>Comfortable with basic fitting and ship operation</Typography>
                                                    </Box>
                                                </>
                                            )}
                                            {experienceLevel === 'INTERMEDIATE' && (
                                                <>
                                                    <Box display="flex" alignItems="center" mb={1}>
                                                        <StarIcon color="primary" sx={{ mr: 1 }} />
                                                        <Typography>Can handle Level 4 missions, low-sec mining & basic PvP</Typography>
                                                    </Box>
                                                    <Box display="flex" alignItems="center" mb={1}>
                                                        <StarIcon color="primary" sx={{ mr: 1 }} />
                                                        <Typography>Understands mechanics like market trading and fleets</Typography>
                                                    </Box>
                                                    <Box display="flex" alignItems="center" mb={1}>
                                                        <StarIcon color="primary" sx={{ mr: 1 }} />
                                                        <Typography>Experience with ship specialisation</Typography>
                                                    </Box>
                                                </>
                                            )}
                                            {experienceLevel === 'VETERAN' && (
                                                <>
                                                    <Box display="flex" alignItems="center" mb={1}>
                                                        <MilitaryTechIcon color="primary" sx={{ mr: 1 }} />
                                                        <Typography>Extensive PvP, null-sec, or wormhole experience</Typography>
                                                    </Box>
                                                    <Box display="flex" alignItems="center" mb={1}>
                                                        <MilitaryTechIcon color="primary" sx={{ mr: 1 }} />
                                                        <Typography>Proficient in PI, T2/T3 production or fleet mechanics</Typography>
                                                    </Box>
                                                    <Box display="flex" alignItems="center" mb={1}>
                                                        <MilitaryTechIcon color="primary" sx={{ mr: 1 }} />
                                                        <Typography>Deep knowledge of EVE's economy, alliances and combat</Typography>
                                                    </Box>
                                                </>
                                            )}
                                        </Box>
                                    </Typography>
                                </Box>


                            </Grid>
                        </Grid>}
                    </Box>
                </LayoutContainer>

            </Box>
        </section>
    );
};

export default PlayerExperienceForm;
