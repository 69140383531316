import React, { useState, useEffect } from 'react';
import { Box, Typography, Grid, CircularProgress } from '@mui/material';
import KnowledgebaseArticleCard from '../eve-components/KnowledgebaseArticleCard';
import LayoutContainer from '../components/LayoutContainer';

interface EveArticleSectionProps {
    title: string;
    fetchUrl: string; // API endpoint to fetch articles
}

const EveArticleSection: React.FC<EveArticleSectionProps> = ({ title, fetchUrl }) => {
    const [articles, setArticles] = useState<any[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<boolean>(false); // New error state

    useEffect(() => {
        const fetchArticles = async () => {
            try {
                const response = await fetch(fetchUrl);
                if (!response.ok) {
                    throw new Error('Failed to fetch articles');
                }
                const data = await response.json();
                setArticles(data.slice(0, 3)); // Limit to 3 articles
                if (data.length === 0) {
                    setError(true); // No articles found
                }
            } catch (err) {
                console.error(`Error fetching articles from ${fetchUrl}:`, err);
                setError(true); // Set error if fetching fails
            }
            setLoading(false);
        };

        fetchArticles();
    }, [fetchUrl]);

    if (loading) {
        return (
            <Box sx={{ textAlign: 'center', marginTop: '20vh' }}>
                <CircularProgress />
            </Box>
        );
    }

    return (
        <LayoutContainer>
            <Box sx={{ mt: 8, mb: 12, position: 'relative', minHeight: 100 }}>
                <Typography variant="h4" sx={{ mb: 4 }}>
                    {title}
                </Typography>

                {error ? (
                    <Typography variant="body1" color="error" sx={{ mt: 4 }}>
                        Unable to load articles or no articles available.
                    </Typography>
                ) : (
                    <Grid container spacing={4} sx={{ minHeight: 100, width: '100%' }}>
                        {articles.length === 0 ? (
                            <Typography variant="body1" color="textSecondary" sx={{ mt: 4 }}>
                                No articles found.
                            </Typography>
                        ) : (
                            articles.map((article) => (
                                <Grid item xs={12} md={4} key={article.slug}>
                                    <KnowledgebaseArticleCard
                                        title={article.title}
                                        content_synopsis={article.content_synopsis}
                                        thumb_url={article.thumb_url}
                                        main_category={article.main_category}
                                        sub_category={article.sub_category}
                                        article_category={article.article_category}
                                        updated_at={article.updated_at}
                                        content_classification={article.content_classification}
                                        slug={article.slug} // Passing slug for navigation
                                    />
                                </Grid>
                            ))
                        )}
                    </Grid>
                )}
            </Box>
        </LayoutContainer>
    );
};

export default EveArticleSection;
