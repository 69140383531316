import { Box, Typography, Button } from '@mui/material';
import LayoutContainer from '../components/LayoutContainer';
import { Signpost } from '@mui/icons-material';

const EveDiplomaticSection: React.FC = () => {
    return (
        <Box component="section" id="creatorProgram" sx={{ position: 'relative', py: 4 }}>
            <Box sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                userSelect: 'none',
                pointerEvents: 'none',
                backgroundImage: "url('/assets/banners/banner_creators.jpg')",
                backgroundSize: 'cover',
                backgroundPosition: 'center',
            }}>
            </Box>
            <LayoutContainer>
                <Box sx={{ p: 4, position: 'relative' }}>
                    <Typography variant="h4" sx={{ textAlign: 'center', mb: 2, color: 'primary.main' }}>
                        Looking for diplomatic channels?
                    </Typography>
                    <Typography variant="body1" sx={{ margin: '0 auto', textAlign: 'center', mb: 3, maxWidth: { md: '80%', xs: 'auto' } }}>
                        We offer support to all players without prejudice. We reserve the right to refuse assistance to players with a history of aggression towards us. We may offer preferential rates for our services to allies.
                    </Typography>
                    <Box sx={{ textAlign: 'center' }}>
                        <Button href="https://forms.gle/mG8FnsbCzNGTXeyP6"
                            sx={{
                                p: 2,
                                pt: 1,
                                pb: 1,
                                bgcolor: '#af5166',
                                color: 'primary.contrastText',
                                '&:hover': {
                                    bgcolor: '#d9738a',
                                    boxShadow: '0px 0px 2px rgba(0,0,0,0.5)'
                                },
                            }}
                            startIcon={<Signpost />}
                        >
                            Diplomatic Request Form
                        </Button>
                    </Box>
                </Box>

            </LayoutContainer>
        </Box>
    );
};

export default EveDiplomaticSection;
