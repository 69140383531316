import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, CircularProgress, Typography, Paper } from '@mui/material';
import LayoutContainer from '../components/LayoutContainer';
import { getQuizSession } from './PageEveQuizEngine';

const PageQuizResults: React.FC = () => {
    const { token } = useParams<{ token: string }>(); // Get token from URL

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [quizSession, setQuizSession] = useState<any | null>(null);
    
    if (!token) {
        return (
            <Box sx={{ textAlign: 'center', marginTop: '20vh' }}>
                <Typography color="error">A valid quiz token is required.</Typography>
            </Box>
        );
    }
    // Fetch the quiz session data
    useEffect(() => {
        const fetchQuizSession = async () => {
            setLoading(true);
            try {
                const response = await getQuizSession(token);
                if (!response.ok) throw new Error('Failed to fetch quiz session');
                const data = await response.json();
                setQuizSession(data.session);
            } catch (err) {
                setError((err as Error).message || 'Unknown error occurred');
            } finally {
                setLoading(false);
            }
        };

        fetchQuizSession();
    }, [token]);

    if (loading) {
        return (
            <Box sx={{ textAlign: 'center', marginTop: '20vh' }}>
                <CircularProgress />
            </Box>
        );
    }

    if (error) {
        return (
            <Box sx={{ textAlign: 'center', marginTop: '20vh' }}>
                <Typography color="error">{error}</Typography>
            </Box>
        );
    }

    if (!quizSession) {
        return null;
    }

    const { in_game_name, quiz_completed_at, result, topics, difficulty, score, desired_question_count } = quizSession;
    const isPassed = result >= Math.ceil(desired_question_count * 0.7); // 70% is considered a pass
    const quizResult = isPassed ? 'Passed' : 'Failed';

    return (
        <LayoutContainer>
            <Box sx={{ padding: '32px', pt: 10 }}>
                <Paper sx={{ padding: '24px', maxWidth: '800px', margin: '0 auto', backgroundColor: '#111' }}>
                    <Typography variant="h4" sx={{ textAlign: 'center', mb: 4 }}>
                        Quiz Results
                    </Typography>

                    {/* In-Game Name */}
                    <Typography variant="h6">In-Game Name:</Typography>
                    <Typography variant="body1" sx={{ mb: 2 }}>
                        {in_game_name}
                    </Typography>

                    {/* Quiz Result */}
                    <Typography variant="h6">Result:</Typography>
                    <Typography variant="body1" sx={{ mb: 2, color: isPassed ? 'green' : 'red' }}>
                        {quizResult} ({result}/{desired_question_count} correct answers)
                    </Typography>

                    {/* Topics */}
                    <Typography variant="h6">Topics Covered:</Typography>
                    <Typography variant="body1" sx={{ mb: 2 }}>
                        {topics.join(', ')}
                    </Typography>

                    {/* Difficulty Level */}
                    <Typography variant="h6">Difficulty Level:</Typography>
                    <Typography variant="body1" sx={{ mb: 2 }}>
                        {difficulty.charAt(0) + difficulty.slice(1).toLowerCase()}
                    </Typography>

                    {/* Quiz Date */}
                    <Typography variant="h6">Quiz Completed At:</Typography>
                    <Typography variant="body1" sx={{ mb: 2 }}>
                        {new Date(quiz_completed_at).toLocaleString()}
                    </Typography>
                </Paper>
            </Box>
        </LayoutContainer>
    );
};

export default PageQuizResults;
