import React, { useState, useEffect } from 'react';
import { Box, Typography, MenuItem, Select, Avatar, Button, CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';

// URL for fetching EVE Online character avatar
const getCharacterAvatarUrl = (characterID: number) => 
  `https://images.evetech.net/characters/${characterID}/portrait?size=64`;

interface EveCharacterPickerProps {
  onCharacterSelect: (character: { characterID: number; characterName: string }) => void; // Add this prop
}

const EveCharacterPicker: React.FC<EveCharacterPickerProps> = ({ onCharacterSelect }) => {
  const [eveCharacters, setEveCharacters] = useState<{ characterID: number; characterName: string }[]>([]);
  const [selectedCharacter, setSelectedCharacter] = useState<number | null>(null); // Start with no selection
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch the current user's EVE characters
    const fetchEveCharacters = async () => {
      try {
        const response = await fetch('/api/users/current');
        const data = await response.json();
        if (data.eveCharacters) {
          setEveCharacters(data.eveCharacters);
        } else {
          setEveCharacters([]);
        }
      } catch (err) {
        console.error('Error fetching EVE characters:', err);
        setError('Failed to load EVE characters');
      } finally {
        setLoading(false);
      }
    };

    fetchEveCharacters();
  }, []);

  const handleChange = (event: any) => {
    const selectedID = event.target.value;
    setSelectedCharacter(selectedID);
    const selectedCharacter = eveCharacters.find((char) => char.characterID === selectedID);
    if (selectedCharacter) {
      onCharacterSelect(selectedCharacter); // Trigger the character selection
    }
  };

  // Handle loading state
  if (loading) {
    return (
      <Box sx={{ textAlign: 'center', mt: 4 }}>
        <CircularProgress />
        <Typography variant="h6" sx={{ mt: 2 }}>
          Loading EVE Characters...
        </Typography>
      </Box>
    );
  }

  // Handle error state
  if (error) {
    return (
      <Box sx={{ textAlign: 'center', mt: 4 }}>
        <Typography variant="h6" sx={{ color: 'red' }}>
          {error}
        </Typography>
      </Box>
    );
  }

  // If no characters are linked, show the message and redirect option
  if (eveCharacters.length === 0) {
    return (
      <Box sx={{ textAlign: 'center', mt: 4 }}>
        <Typography variant="h6" sx={{ mb: 2 }}>No EVE characters linked</Typography>
        <Button 
          variant="contained" 
          color="primary" 
          onClick={() => navigate('/settings/connections')}>
          Link EVE Character
        </Button>
      </Box>
    );
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 4 }}>
      <Typography variant="h6" sx={{ mb: 2 }}>Select Your EVE Character</Typography>

      {/* Dropdown for selecting EVE character */}
      <Select
        value={selectedCharacter || ''} // No character is selected by default
        onChange={handleChange}
        displayEmpty
        sx={{ minWidth: 300, mb: 4, backgroundColor: '#333', color: '#fff', borderRadius: '8px' }}
        renderValue={(selected) => {
          const character = eveCharacters.find((char) => char.characterID === selected);
          return character ? (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Avatar src={getCharacterAvatarUrl(character.characterID)} sx={{ mr: 2 }} />
              <Typography sx={{ color: '#fff' }}>{character.characterName}</Typography>
            </Box>
          ) : 'Select a Character';
        }}
      >
        {eveCharacters.map((character) => (
          <MenuItem key={character.characterID} value={character.characterID}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Avatar src={getCharacterAvatarUrl(character.characterID)} sx={{ mr: 2 }} />
              <Typography>{character.characterName}</Typography>
            </Box>
          </MenuItem>
        ))}
      </Select>

      {selectedCharacter && (
        <Box sx={{ textAlign: 'center', mt: 2 }}>
          <Avatar 
            src={getCharacterAvatarUrl(selectedCharacter)} 
            sx={{ width: 128, height: 128, mx: 'auto' }} 
          />
          <Typography variant="h6" sx={{ mt: 2 }}>
            {eveCharacters.find((char) => char.characterID === selectedCharacter)?.characterName}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default EveCharacterPicker;
