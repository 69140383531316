import React, { useState, useEffect, ReactNode } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { Box, Button, CircularProgress, TextField, Typography, Grid, Autocomplete, FormControl, Select, MenuItem, InputLabel, SelectChangeEvent } from '@mui/material';
import { useAuth } from '../context/AuthContext';
import LayoutContainer from '../components/LayoutContainer';
import KnowledgebaseAutocomplete from '../eve-components/KnowledgebaseAutocomplete';

const PageCreateQuizQuestion: React.FC = () => {
    const { user } = useAuth();
    const navigate = useNavigate();
    const { id } = useParams<{ id: string }>(); // For edit mode
    const location = useLocation(); // To get the query string
    const editMode = !!id; // If ID exists, we are in edit mode
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);

    const [formData, setFormData] = useState({
        level: 'NOVICE',
        topic: 'INDUSTRY',
        question: '',
        correct_answer: '',
        answers: ['', '', '', ''], // Four possible answers
        explanation: '',
        image_url: '',
        audio_url: '',
        related_item: -1, // KnowledgebaseItem id
    });

    const [knowledgebaseItems, setKnowledgebaseItems] = useState<any[]>([]); // List of knowledgebase items to choose from

    // Fetch quiz question data if in edit mode
    useEffect(() => {
        if (editMode) {
            const fetchQuestion = async () => {
                try {
                    setLoading(true);
                    const response = await fetch(`/api/quiz/question/${id}`);
                    const question = await response.json();
                    setFormData({
                        level: question.level,
                        topic: question.topic,
                        question: question.question,
                        correct_answer: question.correct_answer,
                        answers: question.answers,
                        explanation: question.explanation,
                        image_url: question.image_url || '',
                        audio_url: question.audio_url || '',
                        related_item: question.related_item.id,
                    });
                    setKnowledgebaseItems([question.related_item]);
                    setLoading(false);
                } catch (err) {
                    setError('Failed to fetch quiz question.');
                    setLoading(false);
                }
            };
            fetchQuestion();
        }
    }, [editMode, id]);

    // Fetch knowledgebase items for associating with the question
    useEffect(() => {
        const fetchKnowledgebaseItems = async () => {
            try {
                const response = await fetch('/api/kb/articles/all'); // Adjust the endpoint if necessary
                const data = await response.json();
                setKnowledgebaseItems(data);
            } catch (err) {
                console.error('Failed to fetch knowledgebase items:', err);
            }
        };

        fetchKnowledgebaseItems();
    }, []);

    // Read query string to check for relatedid
    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const relatedId = queryParams.get('relatedid');
        if (relatedId) {
            setFormData((prev) => ({
                ...prev,
                related_item: parseInt(relatedId, 10),
            }));
        }
    }, [location.search]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSelectChange = (event: SelectChangeEvent<string>, child: ReactNode) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value
        });
    }

    const handleAnswerChange = (index: number, value: string) => {
        const newAnswers = [...formData.answers];
        newAnswers[index] = value;
        setFormData({
            ...formData,
            answers: newAnswers
        });
    };

    const handleSubmit = async () => {
        setLoading(true);
        setError(null);

        try {
            const method = editMode ? 'PUT' : 'POST';
            const url = editMode ? `/api/quiz/question/${id}` : '/api/quiz/question';

            if (formData.related_item === -1) {
                setError('The related knowledgebase item must be provided, where users can learn the answer.');
                setLoading(false);
                return;
            }

            const response = await fetch(url, {
                method,
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    ...formData,
                    knowledgebaseItemId: formData.related_item,
                }),
            });

            const result = await response.json();

            if (!response.ok) {
                setError(result.errors ? result.errors.map((e: any) => e.msg).join('\n\n') : `Failed to ${editMode ? 'update' : 'create'} quiz question.`);
                setLoading(false);
                return;
            }

            setLoading(false);
            navigate(`/quiz/questions`); // Navigate to the quiz question list or another appropriate page
        } catch (err) {
            console.error('Error submitting quiz question:', err);
            setError(`Failed to ${editMode ? 'update' : 'create'} quiz question. Please try again.`);
            setLoading(false);
        }
    };

    return (
        <Box component="section" sx={{ position: 'relative', mt: 8 }}>
            <LayoutContainer>
                <Typography variant="h4" sx={{ textAlign: 'center', mt: 4 }}>
                    {editMode ? 'Edit' : 'Create'} Quiz Question
                </Typography>

                <Box sx={{ margin: '0 auto', mt: 4 }}>
                    {error && <Typography color="error">{error}</Typography>}

                    {/* Question Text */}
                    <FormControl fullWidth margin="normal">
                        <TextField
                            label="Question"
                            name="question"
                            value={formData.question}
                            onChange={handleChange}
                            required
                        />
                    </FormControl>

                    {/* Level and Topic */}
                    <Grid container spacing={2} marginBottom={2}>
                        <Grid item xs={6}>
                            <FormControl fullWidth margin="normal">
                                <InputLabel>Level</InputLabel>
                                <Select
                                    name="level"
                                    value={formData.level}
                                    onChange={handleSelectChange}
                                >
                                    <MenuItem value="NOVICE">Novice</MenuItem>
                                    <MenuItem value="BEGINNER">Beginner</MenuItem>
                                    <MenuItem value="INTERMEDIATE">Intermediate</MenuItem>
                                    <MenuItem value="EXPERT">Expert</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth margin="normal">
                                <InputLabel>Topic</InputLabel>
                                <Select
                                    name="topic"
                                    value={formData.topic}
                                    onChange={handleSelectChange}
                                >
                                    <MenuItem value="INDUSTRY">Industry</MenuItem>
                                    <MenuItem value="COMBAT">Combat</MenuItem>
                                    <MenuItem value="LORE">Lore</MenuItem>
                                    <MenuItem value="UI">UI</MenuItem>
                                    <MenuItem value="POLITICS">Politics</MenuItem>
                                    <MenuItem value="ECONOMY">Economy</MenuItem>
                                    <MenuItem value="EXPLORATION">Exploration</MenuItem>
                                    <MenuItem value="TRADE">Trade</MenuItem>
                                    <MenuItem value="PILOTING">Piloting</MenuItem>
                                    <MenuItem value="NAVIGATION">Navigation</MenuItem>
                                    <MenuItem value="SAVING">Saving</MenuItem>
                                    <MenuItem value="FLEET_MANAGEMENT">Fleet Management</MenuItem>
                                    <MenuItem value="SALVAGING">Salvaging</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>

                    {/* Correct Answer and Answers */}
                    <Typography variant="h6" sx={{ mt: 4 }}>Answers</Typography>
                    {formData.answers && formData.answers.map((answer, index) => (
                        <FormControl fullWidth margin="normal" key={index}>
                            <TextField
                                label={`Answer ${String.fromCharCode(65 + index)}`} // A, B, C, D
                                value={answer}
                                onChange={(e) => handleAnswerChange(index, e.target.value)}
                            />
                        </FormControl>
                    ))}

                    {/* Correct Answer */}
                    <FormControl fullWidth margin="normal">
                        <InputLabel>Correct Answer</InputLabel>
                        <Select
                            name="correct_answer"
                            value={formData.correct_answer}
                            onChange={handleSelectChange}
                        >
                            {formData.answers.map((_, index) => (
                                <MenuItem key={index} value={String.fromCharCode(65 + index)}>
                                    {`Answer ${String.fromCharCode(65 + index)}`}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    {/* Explanation */}
                    <FormControl fullWidth margin="normal">
                        <TextField
                            label="Explanation"
                            name="explanation"
                            value={formData.explanation}
                            onChange={handleChange}
                            required
                        />
                    </FormControl>

                    {/* Related Knowledgebase Item */}
                    <FormControl fullWidth margin="normal">
                        <KnowledgebaseAutocomplete
                            onSelect={(id: number | null) => {
                                if (id != null) {
                                    setFormData({ ...formData, related_item: id })
                                }
                            }
                            }
                        />
                    </FormControl>

                    {/* Media URLs */}
                    <Grid container spacing={2} marginBottom={2}>
                        <Grid item xs={6}>
                            <FormControl fullWidth margin="normal">
                                <TextField
                                    label="Image URL"
                                    name="image_url"
                                    value={formData.image_url}
                                    onChange={handleChange}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth margin="normal">
                                <TextField
                                    label="Audio URL"
                                    name="audio_url"
                                    value={formData.audio_url}
                                    onChange={handleChange}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>

                    <Button
                        variant="contained"
                        color="primary"
                        sx={{ mt: 4 }}
                        onClick={handleSubmit}
                        disabled={loading}
                    >
                        {loading ? <CircularProgress size={24} /> : editMode ? 'Update Question' : 'Create Question'}
                    </Button>
                </Box>
            </LayoutContainer>
        </Box>
    );
};

export default PageCreateQuizQuestion;
