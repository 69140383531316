import React from 'react';
import { Box, Typography, Tooltip, Link } from '@mui/material';

interface EtymologyHintProps {
    name: string;
    category: string;
    description: string;
    icon_url?: string;
    moreInfoLink: string; // Link to learn more about the topic
}
const EtymologyHint: React.FC<EtymologyHintProps> = ({ name, category, description, icon_url, moreInfoLink }) => {
    return (
        <Tooltip
            title={
                <>
                    <Box sx={{ display: 'flex', alignItems: 'center', p: 1, width: 400 }}>
                        {icon_url && (
                            <Box sx={{ mr: 2 }}>
                                <img src={icon_url} alt={name} style={{ width: 64, height: 64, borderRadius: '50%' }} />
                            </Box>
                        )}
                        <Box>
                            <Typography variant="h6" fontWeight="bold" color="inherit">
                                {name}
                            </Typography>
                            <Typography variant="body2">
                                {category.toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase())}
                            </Typography>
                        </Box>
                    </Box>
                    <Box sx={{ display: 'flex', p: 1, pt: 0 }}>
                        <Typography variant="body2" sx={{ mt: 1 }}>
                            {description}
                        </Typography>

                    </Box>
                    <Box sx={{ display: 'flex', p: 1, pt: 0 }}>
                        <Typography variant="body2">
                            <Link href={moreInfoLink} target="_blank" rel="noopener" sx={{
                                mt: 1,
                                display: 'block',
                                textDecoration: 'none', borderRadius: 2, backgroundColor: '#333', padding: 1,
                                color: '#fff', '&:hover': {
                                    backgroundColor: '#555',
                                }
                            }}>
                                Learn more
                            </Link>
                        </Typography>

                    </Box>
                </>
            }
            arrow
            placement="top"
            componentsProps={{
                tooltip: {
                    sx: {
                        backgroundColor: '#111111dd',
                        color: 'white',
                        backdropFilter: 'blur(5px)',
                        boxShadow: '0px 5px 12px #000',
                        p: 2,
                        minWidth: 400,
                        borderRadius: '8px',
                        userSelect: 'none'
                    }
                },
                arrow: {
                    sx: {
                        color: '#222222',
                    }
                }
            }}
        >
            <Typography component="span" sx={{ color: 'orange', fontWeight: 'bold', cursor: 'pointer', '&:hover': { opacity: 0.6 } }}>{name}</Typography>
        </Tooltip>
    );
};

export default EtymologyHint;
