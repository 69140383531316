import React, { useState, useEffect, ReactElement } from 'react';
import { Box, Typography, List, ListItem, ListItemText, ListItemIcon, Grid, Button, Icon } from '@mui/material';
import styled, { keyframes } from 'styled-components';
import LayoutContainer from '../components/LayoutContainer';

// Define the type for each benefit
export interface EveBenefit {
    image: string;
    icon: ReactElement;
    heading: string;
    description: string;
    learnMoreAction: string;
    learnMoreLink: string;
}

// Define the props type for EveBenefitsSection
interface EveBenefitsSectionProps {
    benefits: EveBenefit[];
}

// Keyframe for fading effect
const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

// Styled components for layout and fade animation
const Section = styled.section`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
`;

const LeftColumn = styled.div`
  flex: 1;
  padding: 30px;
  padding-right: 20px;
`;

const RightColumn = styled.div`
  flex: 1;
  padding: 30px;
  animation: ${fadeIn} 0.5s ease;
`;

const BenefitImage = styled.img`
  width: 100%;
  height: auto;
  margin-bottom: 10px;
  object-fit: contain;
`;

// Main EveBenefitsSection component
const EveBenefitsSection: React.FC<EveBenefitsSectionProps> = ({ benefits }) => {
    const [selectedBenefitIndex, setSelectedBenefitIndex] = useState<number | null>(null);
    const [currentBenefitIndex, setCurrentBenefitIndex] = useState<number>(0);

    // Automatically cycle through benefits if no benefit is selected
    useEffect(() => {
        if (selectedBenefitIndex === null) {
            const interval = setInterval(() => {
                setCurrentBenefitIndex((prevIndex) => (prevIndex + 1) % benefits.length);
            }, 6000);
            return () => clearInterval(interval); // Cleanup on unmount or when selection changes
        }
    }, [selectedBenefitIndex, benefits.length]);

    // Handle benefit click
    const handleBenefitClick = (index: number) => {
        setSelectedBenefitIndex(index);
        setCurrentBenefitIndex(index); // Stop cycling and show selected benefit
    };

    // Get the currently displayed benefit
    const displayedBenefit = benefits[currentBenefitIndex];

    return (
        <Section style={{ position: 'relative' }}>
            <Box sx={{ position: 'absolute', left: 0, right: 0, top: 0, bottom: 0, overflow: 'hidden', opacity: 0.6 }}>
                <div style={{ position: 'absolute', left: 0, right: 0, top: 0, bottom: 0, transition: 'all 0.7s', backgroundImage: `url(${displayedBenefit.image})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>

                </div>
            </Box>
            <Box sx={{ position: 'absolute', left: '50%', right: 0, paddingLeft: '100px', top: -30, bottom: 0, overflow: 'hidden' }}>
                <div style={{ color: '#fff', opacity: 0.3, zoom: 25, position: 'absolute' }}>
                    {displayedBenefit.icon}
                </div>

            </Box>

            <Box sx={{ position: 'absolute', left: 0, right: 0, top: 0, bottom: 0, background: 'linear-gradient(0deg, rgba(17,15,18,1) 5%, rgba(17,15,18,0.3) 40%, rgba(17,15,18,0.7) 50%, rgba(17,15,18,1) 100%);' }}>

            </Box>
            {/* Left Column with Text and Benefits List */}
            <LayoutContainer>
                <Box sx={{ position: 'relative', zoom: 1.1 }}>
                    <Grid container pt={4} pb={4}>
                        <Grid item xs={12} md={6} p={4}>
                            <Typography variant="body1" gutterBottom>
                                Discover how we can help you thrive in New Eden.
                            </Typography>

                            <List>
                                {benefits.map((benefit, index) => (
                                    <ListItem
                                        key={index}
                                        button
                                        onClick={() => handleBenefitClick(index)}
                                        selected={selectedBenefitIndex === index}
                                        sx={{ backdropFilter: 'blur(4px)', borderRadius: 2, margin: 1 }}
                                    >
                                        <ListItemIcon sx={{ color: '#fff', minWidth: 0, pr: 1.5 }}>{benefit.icon}</ListItemIcon>
                                        <ListItemText primary={benefit.heading} />
                                    </ListItem>
                                ))}
                            </List>
                        </Grid>
                        {/* selected benefit */}
                        <Grid item xs={12} md={6} p={4}>
                            <Box
                                component={"div"}
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    height: '100%', // Full height of the column
                                    textAlign: 'center',
                                    textShadow: '0px 2px 2px #000, 0px 5px 15px #000',
                                }}
                            >
                                <Typography variant="h4">
                                    {displayedBenefit.heading}
                                </Typography>
                                <Typography variant="body1" fontSize={18} p={4}>
                                    {displayedBenefit.description}
                                </Typography>
                                <Button sx={{ backgroundColor: '#797979', p: 1, pl: 2, pr: 2 }} target='_blank' href={displayedBenefit.learnMoreLink}>
                                    {displayedBenefit.icon}
                                    <Typography variant="body1" fontSize={14} pl={1}>
                                    {displayedBenefit.learnMoreAction}
                                </Typography>
                                   
                                </Button>
                            </Box>
                        </Grid>


                    </Grid>
                </Box>
            </LayoutContainer>
        </Section>
    );
};

export default EveBenefitsSection;
