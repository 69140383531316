// Example usage in src/pages/HomePage.tsx or a similar file
import React from 'react';
import HeroSection from '../sections/HeroSection';
import { Box, Button, Link, Typography } from '@mui/material';
import BloomRenderer, { BloomRendererScene, BloomRendererSceneElementType } from '../components/BloomRenderer';
import LayoutContainer from '../components/LayoutContainer';
import { Book, Build, Games, Loyalty, RocketRounded } from '@mui/icons-material';
import { Grid } from '@mui/material';
import HowItWorksSection from '../sections/HowItWorks';
import WhatsNewSection from '../sections/WhatsNewHomepage';
import CreatorProgramSection from '../sections/CreatorProgramSection';
import EveDiplomaticSection from '../sections/EveDiplomaticSection';
import EveHowItWorksSection from '../sections/EveHowItWorks';
import EveWhatsNewSection from '../sections/EveWhatsNew';

const ThreeColumnSection: React.FC = () => {
    return (
        <Box component="section" sx={{backgroundColor: '#110f12' }}>

            <LayoutContainer>
                <Grid container spacing={2} sx={{ padding: 4 }}>
                    <Grid item xs={12} md={4}>
                        <Box sx={{ textAlign: 'center', padding: 4 }}>
                            <Loyalty sx={{ fontSize: 40, color: '#fff' }} />
                            <Typography variant="h6" color="contrastText" sx={{ margin: 2 }}>Guidance for All</Typography>
                            <Typography variant="body2" color="contrastText">
                                Get advice and mentorship from experienced players, no matter your corporation.
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Box sx={{ textAlign: 'center', padding: 4 }}>
                            <Build sx={{ fontSize: 40,color: '#fff'  }} />
                            <Typography variant="h6" color="contrastText" sx={{ margin: 2 }}>A Thriving Community</Typography>
                            <Typography variant="body2" color="contrastText">
                            Join a supportive EVE corporation with great benefits and room to grow.
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Box sx={{ textAlign: 'center', padding: 4 }}>
                            <Games sx={{ fontSize: 40,color: '#fff'  }} />
                            <Typography variant="h6" color="contrastText" sx={{ margin: 2 }}>Services for New Eden</Typography>
                            <Typography variant="body2" color="contrastText">
                                Access a range of services, available to everyone with varying requirements.
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </LayoutContainer>

        </Box>
    );
};

const FooterSection: React.FC = () => {
    return (
        <LayoutContainer>
            <Box sx={{ textAlign: 'center', padding: 4 }}>
                <Box sx={{ display: 'none', justifyContent: 'center', gap: 2, mt: 1, padding: 4, }}>
                    <Link sx={{textDecoration: 'none'}}><Typography variant="footerLink">Privacy Policy</Typography></Link>
                    <Link sx={{textDecoration: 'none'}}><Typography variant="footerLink">Sponsorships</Typography></Link>
                    <Link sx={{textDecoration: 'none'}}><Typography variant="footerLink">Careers</Typography></Link>
                </Box>
                <Typography variant="body2" color="#888">
                This domain is part of the Bloomies network and is not affiliated with CCP Games.
                </Typography>
                <Typography variant="body2" color="#888">
                The Everbloom Company is a fictional corporation and any mention of services refers to activities within the EVE Online universe.
                </Typography>
                <Typography variant="body2" color="#888">
                    Bloomies is a platform and brand-concept owned and operated by Everbloom Media Ltd, registered in the UK (Company No. 14974080).
                </Typography>

            </Box>
        </LayoutContainer>
    );
};



const HomePage: React.FC = () => {
    const scene: BloomRendererScene = {
        elements: [{
            type: BloomRendererSceneElementType.Image,
            x: 0,
            y: 0,
            imageUrl: '/assets/scenes/eve_online/background.jpg',
            size: 0,
            _width: 0,
            _height: 0,
            yj: -1,
            xm: 3,
            ym: 2,
        },
        {
            type: BloomRendererSceneElementType.Image,
            x: 150,
            y: 0,
            imageUrl: '/assets/scenes/eve_online/background2.png',
            size: 0,
            _width: 0,
            _height: 0,
            xj: 1,
            yj: -2,
            xm: 6,
            ym: 4,
        },
        // {
        //     type: BloomRendererSceneElementType.Image,
        //     x: 0,
        //     y: 140,
        //     imageUrl: '/assets/scenes/blossom_hills/foreground.png',
        //     size: 0,
        //     _width: 0,
        //     _height: 0,
        //     xj: 1,
        //     yj: 5,
        //     xm: 24,
        //     ym: 14,
        // },
        {
            type: BloomRendererSceneElementType.Image,
            x: 50,
            y: 30,
            imageUrl: '/assets/scenes/eve_online/sakura1.png',
            size: 0,
            _width: 0,
            _height: 0,
            yj: 8,
            xj: -4,
            xm: 30,
            ym: 22,
        },
        ]
    };
    const background = <BloomRenderer scene={scene} zoom={0.52} width={930} height={300} sx={{ filter: 'brightness(0.85)' }}></BloomRenderer>
    const buttonStyle: React.CSSProperties = {
        margin: 1,
        padding: '8px 15px',
        width: '180px',
        fontSize: '20px',
        borderRadius: 0,
        boxShadow: '0px 2px 2px rgba(0,0,0,0.2)'
    }
    return (
        <><HeroSection backgroundComponent={background} fill={true}>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                height: '100%',
                position: 'relative',
                filter: 'drop-shadow(0px 10px 40px rgba(255, 255, 255, 0.6))',
            }} id="flexHero">
                <Box sx={{
                    textShadow: '0px 2px 2px rgba(0, 0, 0, 0.8)',
                    margin: '0 auto',
                    padding: 4,
                    borderRadius: 8,
                    userSelect: 'none',
                }}>
                    <LayoutContainer>
                        <Box sx={{
                            padding: 3,
                            filter: 'drop-shadow(0px 5px 12px rgba(0,0,0,0.5))',
                        }}>
                            <Typography variant="heroSub" color="primary.contrastText" align="center">
                            We are an EVE Online corporation dedicated to helping all players.
                            </Typography>
                            <Typography variant="heroSub" color="primary.contrastText" align="center">
                            Our site offers valuable resources and services regardless of membership!
                            </Typography>
                    
                        </Box>

                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'center', // Centers the buttons horizontally
                            mt: 0, // Adds margin top for spacing from text above
                        }}>
                            <Button
                                sx={{
                                    ...buttonStyle,
                                    bgcolor: '#3d3d3d',
                                    color: 'primary.contrastText',
                                    '&:hover': {
                                        bgcolor: '#626564',
                                        boxShadow: '0px 0px 2px rgba(0,0,0,0.5)'
                                    },
                                }}
                                startIcon={<Book />}
                                href="#howItWorks"
                            >
                                Learn more
                            </Button>
                            <Button

                              href="/register"
                                sx={{
                                    ...buttonStyle,
                                    display: 'none',
                                    bgcolor: '#af5166',
                                    color: 'primary.contrastText',
                                    '&:hover': {
                                        bgcolor: '#d9738a',
                                        boxShadow: '0px 0px 2px rgba(0,0,0,0.5)'
                                    },
                                }}
                                startIcon={<RocketRounded />}
                            >
                                Sign up
                            </Button>
                        </Box>
                    </LayoutContainer>

                </Box>
            </Box>
        </HeroSection>
        <ThreeColumnSection></ThreeColumnSection>
        <EveHowItWorksSection></EveHowItWorksSection>
        <EveWhatsNewSection></EveWhatsNewSection>
        <EveDiplomaticSection></EveDiplomaticSection>
        <FooterSection></FooterSection>
        </>
    );

};

export default HomePage;
