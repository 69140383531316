import React, { useEffect, useState } from 'react';
import { Box, Typography, Paper, Button, List, ListItem, ListItemText, ListItemIcon, IconButton, CircularProgress, Divider, Grid } from '@mui/material';
import { useAuth } from '../context/AuthContext';
import { Delete, AddCircle, Link, CheckCircle } from '@mui/icons-material';
import LayoutContainer from '../components/LayoutContainer';
import InfoBox from '../components/InfoBox'; // Assuming this is for displaying infobox messages
import { useLocation } from 'react-router-dom'; // To get the query parameter from URL

const PageSettingsConnections: React.FC = () => {
    const { user } = useAuth(); // Assuming user context gives us Discord information
    const [eveConnections, setEveConnections] = useState<any[]>([]); // EVE Online connections
    const [loading, setLoading] = useState<boolean>(true);
    const [successCharacter, setSuccessCharacter] = useState<string | null>(null);

    const location = useLocation(); // Use location to get the current URL

    // Check if there's a success query parameter and get its value
    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const successCharacterName = params.get('success');
        if (successCharacterName) {
            setSuccessCharacter(successCharacterName);
        }
    }, [location.search]);

    // Fetch Discord and EVE Online connections
    useEffect(() => {
        // Simulate API calls to fetch connections
        const fetchConnections = async () => {
            setLoading(true);
            try {
                const eveResponse = await fetch('/api/eve/authorizations'); // Replace with real API
                const eveData = await eveResponse.json();
                setEveConnections(eveData);
            } catch (error) {
                console.error('Failed to fetch connections', error);
            }
            setLoading(false);
        };
        fetchConnections();
    }, []);

    const handleAddEveConnection = () => {
        window.location.href = '/auth/eveonline'; // Redirect to EVE Online OAuth flow
    };

    const handleRemoveEveConnection = async (characterId: number) => {
        setLoading(true);
        try {
            await fetch(`/api/eve/authorizations/${characterId}`, { method: 'DELETE' });
            setEveConnections((prev) => prev.filter((conn) => conn.characterID !== characterId));
        } catch (error) {
            console.error('Failed to remove EVE connection', error);
        }
        setLoading(false);
    };

    if (loading) {
        return (
            <Box sx={{ textAlign: 'center', marginTop: '20vh' }}>
                <CircularProgress />
            </Box>
        );
    }

    return (
        <Box component="section" sx={{ backgroundColor: '#212022', p: 3, borderRadius: '12px', minHeight: '400px' }}>
            <LayoutContainer>
                <Typography variant='h3' sx={{ mb: 2 }}>Connections</Typography>


                <Typography variant='body2' sx={{ pb: 2 }}>
                    Manage your connected accounts for Discord and EVE Online.
                </Typography>

                {successCharacter && (
                    <Paper sx={{ p: 2, mb: 2, backgroundColor: '#2e7d32', color: '#fff', display: 'flex', alignItems: 'center' }}>
                        <CheckCircle sx={{ fontSize: 40, mr: 2 }} />
                        <Typography variant="body1">
                            Successfully connected <strong>{successCharacter}</strong> to your account.
                        </Typography>
                    </Paper>
                )}

                {/* Discord Connection */}
                <Paper sx={{ p: 2, mb: 2, backgroundColor: '#2b2a2c', color: '#fff' }}>
                    <Typography variant="h5" sx={{ mb: 2 }}>
                        Discord Connection
                    </Typography>
                    <Typography variant="body1">
                        Connected as: <strong>{user?.username || 'Unknown'}</strong>
                    </Typography>
                    <Typography variant="body2" sx={{ mt: 1, color: '#888' }}>
                        This connection cannot be removed.
                    </Typography>
                </Paper>


                {/* EVE Online Connections */}
                <Paper sx={{ p: 2, backgroundColor: '#2b2a2c', color: '#fff' }}>
                    <Typography variant="h5" sx={{ mb: 2 }}>
                        EVE Online Connections
                    </Typography>



                    {/* Info box for linking new character */}
                    {eveConnections.length == 0 && <InfoBox
                        title="Linking an EVE Online Character"
                        text="We use the official EVE Single Sign On (SSO) to verify your character information. Clicking the button below to authorize a new EVE Online character will associate it with your discord account for this platform."
                        icon={AddCircle}
                        backgroundColor="#6a5487"
                    />}

                    <Box sx={{ mt: 1, mb: 1, textAlign: 'center', backgroundColor: 'rgba(255, 255, 255, 0.1)', borderRadius: 3, p: 1 }}>
                        <Grid container>

                            <Grid item xs={12} md={6}>
                                <Typography variant="body1">
                                    Use the login button to authorize every character you wish to use on the platform.
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <img
                                    src="https://web.ccpgamescdn.com/eveonlineassets/developers/eve-sso-login-black-large.png"
                                    alt="Login with EVE Online"
                                    style={{ cursor: 'pointer', maxWidth: '300px' }} // Adjust the size as needed
                                    onClick={handleAddEveConnection}
                                />
                            </Grid>
                        </Grid>

                    </Box>

                    {/* List of EVE connections */}
                    <List>
                        {eveConnections.length > 0 ? (
                            eveConnections.map((connection) => (
                                <React.Fragment key={connection.characterID}>
                                    <ListItem sx={{ display: 'flex', justifyContent: 'space-between', backgroundColor: '#333', padding: 0, borderRadius: 3, mb: 2, overflow: 'hidden' }}>
                                        <img
                                            src={`https://images.evetech.net/characters/${connection.characterID}/portrait`}
                                            alt={`${connection.characterName}'s avatar`}
                                            style={{ width: 64, height: 64, marginRight: 4 }}
                                        />

                                        <ListItemText
                                            primary={connection.characterName}

                                            sx={{ color: '#fff', pl: 2, fontWeight: 'bold' }}
                                        />
                                        {/* <ListItemText
                                            primary={`Character ID: ${connection.characterID}`}
                                            sx={{ color: '#888' }}
                                        /> */}
                                        <ListItemIcon>
                                            <IconButton
                                                edge="end"
                                                aria-label="delete"
                                                onClick={() => handleRemoveEveConnection(connection.characterID)}
                                            >
                                                <Delete sx={{ color: '#ff4d4d' }} />
                                            </IconButton>
                                        </ListItemIcon>
                                    </ListItem>
                                    <Divider />
                                </React.Fragment>
                            ))
                        ) : (
                            <Typography variant="body2" sx={{ color: '#888', mt: 2 }}>
                                No EVE Online connections found.
                            </Typography>
                        )}
                    </List>
                </Paper>
            </LayoutContainer>
        </Box>
    );
};

export default PageSettingsConnections;
