import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Typography, CircularProgress, Grid } from '@mui/material';
import KnowledgebaseArticleCard from '../eve-components/KnowledgebaseArticleCard';
import LayoutContainer from '../components/LayoutContainer';
import CategoryIcon from '../eve-components/CategoryIcon';

interface KnowledgebaseArticle {
    title: string;
    content_synopsis: string;
    thumb_url: string;
    main_category: string;
    sub_category?: string;
    article_category?: string;
    updated_at: string;
    content_classification: string;
    slug: string;
}

const PageEveKnowledgebaseCategory: React.FC = () => {
    const { category } = useParams<{ category: string }>(); // Get category from route params
    const [articles, setArticles] = useState<KnowledgebaseArticle[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const [notFound, setNotFound] = useState<boolean>(false); // New state to handle 404

    useEffect(() => {
        const fetchArticles = async () => {
            try {
                setLoading(true);
                setNotFound(false); // Reset the not found state

                const response = await fetch(`/api/kb/articles/category/${category}`);
                if (response.status === 404) {
                    setNotFound(true); // If we get a 404, handle it
                    return;
                }
                if (!response.ok) {
                    throw new Error('Failed to fetch articles.');
                }

                const data = await response.json();
                setArticles(data);
            } catch (err: any) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchArticles();
    }, [category]);

    if (loading) {
        return (
            <Box sx={{ textAlign: 'center', marginTop: '20vh' }}>
                <CircularProgress />
            </Box>
        );
    }

    if (notFound) {
        return (
            <Box sx={{ textAlign: 'center', marginTop: '20vh' }}>
                <Typography variant="h4" color="error">
                    No articles found for "{category}" category.
                </Typography>
                <Typography variant="body1" sx={{ mt: 2 }}>
                    It seems there are no articles available under this category. Please try browsing other categories or check back later.
                </Typography>
            </Box>
        );
    }

    if (error) {
        return (
            <Box sx={{ textAlign: 'center', marginTop: '20vh' }}>
                <Typography variant="h4" color="error">
                    {error}
                </Typography>
            </Box>
        );
    }

    return (
        <Box component="section" sx={{ position: 'relative', mt: 8 }}>
            <LayoutContainer>
                <Box sx={{ padding: 4 }}>
                    <Typography variant="h2" sx={{ mb: 2, zoom: 2.5 }}>
                        <CategoryIcon
                            category={category ? category.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ') : 'Unknown'}
                            nohover
                        />
                    </Typography>


                    <Typography variant="h6" sx={{ mb: 2 }}>
                        Displaying {articles.length} result(s) for {category ?? 'unknown'}.
                    </Typography>
                    {/* Render KnowledgebaseArticleCard for each article */}
                    <Grid container spacing={4}>
                        {articles.map((article) => (
                            <Grid item xs={12} sm={6} md={4} key={article.title}>
                                <KnowledgebaseArticleCard
                                    title={article.title}
                                    content_synopsis={article.content_synopsis}
                                    thumb_url={article.thumb_url}
                                    main_category={article.main_category}
                                    sub_category={article.sub_category}
                                    article_category={article.article_category}
                                    updated_at={article.updated_at}
                                    content_classification={article.content_classification}
                                    slug={article.slug}
                                />
                            </Grid>
                        ))}
                    </Grid>

                </Box>
            </LayoutContainer>
        </Box>
    );
};

export default PageEveKnowledgebaseCategory;
