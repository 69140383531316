import React, { useEffect, useState } from 'react';
import {
    Box,
    Button,
    CircularProgress,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
    Typography,
    TableSortLabel,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import LayoutContainer from '../components/LayoutContainer';

interface Etymology {
    id: number;
    name: string;
    short_description: string;
    category: string;
    icon_url: string;
    image_url?: string;
}

const PageEtymologies: React.FC = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [etymologies, setEtymologies] = useState<Etymology[]>([]);
    const [filter, setFilter] = useState<string>('');
    const [sortBy, setSortBy] = useState<'name' | 'category'>('name');
    const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');

    useEffect(() => {
        const fetchEtymologies = async () => {
            setLoading(true);
            setError(null);

            try {
                const response = await fetch('/api/kb/etymologies');
                if (!response.ok) throw new Error('Failed to fetch etymologies');
                const data = await response.json();
                setEtymologies(data);
                setLoading(false);
            } catch (err) {
                setError('Failed to load etymologies.');
                setLoading(false);
            }
        };

        fetchEtymologies();
    }, []);

    const handleCreateNew = () => {
        navigate('/learn/etymologies/new');
    };

    const handleEdit = (id: number) => {
        navigate(`/learn/etymologies/edit/${id}`);
    };

    const handleSortChange = (column: 'name' | 'category') => {
        const isAsc = sortBy === column && sortDirection === 'asc';
        setSortDirection(isAsc ? 'desc' : 'asc');
        setSortBy(column);
    };

    const sortedAndFilteredEtymologies = etymologies
        .filter((etymology) =>
            etymology.name.toLowerCase().includes(filter.toLowerCase()) ||
            etymology.category.toLowerCase().includes(filter.toLowerCase())
        )
        .sort((a, b) => {
            const valueA = a[sortBy];
            const valueB = b[sortBy];
            if (valueA < valueB) return sortDirection === 'asc' ? -1 : 1;
            if (valueA > valueB) return sortDirection === 'asc' ? 1 : -1;
            return 0;
        });

    return (
        <LayoutContainer>
            <Box sx={{ padding: '32px', pt: 10 }}>
                <Typography variant="h4" sx={{ mb: 4 }}>
                    Etymology Entries
                </Typography>

                <Button
                    variant="contained"
                    color="primary"
                    sx={{ mb: 4 }}
                    onClick={handleCreateNew}
                >
                    Create New Etymology
                </Button>

                {error && <Typography color="error">{error}</Typography>}
                {loading ? (
                    <CircularProgress />
                ) : (
                    <>
                        <TextField
                            label="Filter by name or category"
                            value={filter}
                            onChange={(e) => setFilter(e.target.value)}
                            fullWidth
                            sx={{ mb: 3 }}
                        />

                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        <TableSortLabel
                                            active={sortBy === 'name'}
                                            direction={sortDirection}
                                            onClick={() => handleSortChange('name')}
                                        >
                                            Name
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>
                                        <TableSortLabel
                                            active={sortBy === 'category'}
                                            direction={sortDirection}
                                            onClick={() => handleSortChange('category')}
                                        >
                                            Category
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>Short Description</TableCell>
                                    <TableCell>Icon</TableCell>
                                    <TableCell>Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {sortedAndFilteredEtymologies.map((etymology) => (
                                    <TableRow key={etymology.id}>
                                        <TableCell>{etymology.name}</TableCell>
                                        <TableCell>{etymology.category}</TableCell>
                                        <TableCell>{etymology.short_description}</TableCell>
                                        <TableCell>
                                            <img src={etymology.icon_url} alt={etymology.name} width={40} height={40} />
                                        </TableCell>
                                        <TableCell>
                                            <Button
                                                variant="outlined"
                                                color="primary"
                                                onClick={() => handleEdit(etymology.id)}
                                            >
                                                Edit
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </>
                )}
            </Box>
        </LayoutContainer>
    );
};

export default PageEtymologies;
