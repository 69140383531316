import React, { useState } from 'react';
import styled from 'styled-components';
import { Box, Typography, IconButton, Collapse } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import LayoutContainer from '../components/LayoutContainer';

// Styled component for FAQ item
const FaqItemContainer = styled(Box)`
  background-color: rgba(33, 33, 33, 0.7);
  padding: 0px;
  margin-bottom: 10px;
  border-radius: 8px;
  overflow: hidden;
  cursor: pointer;
  color: #fff;
`;

const FaqTitle = styled(Typography)`
  display: flex;
  justify-content: space-between;
  padding: 16px;
  align-items: center;
  background-color: rgba(44, 44, 44, 0.7);
`;

const FaqContent = styled(Box)`
  padding: 5px 16px;
  color: #ddd;
`;

// FaqQuestion component
const FaqQuestion: React.FC<{ title: string, content: React.ReactElement }> = ({ title, content }) => {
  const [open, setOpen] = useState(false);

  return (
    <FaqItemContainer onClick={() => setOpen(!open)}>
      <FaqTitle variant="h6">
        {title}
        <IconButton>
          {open ? <ExpandLessIcon sx={{ color: '#fff' }} /> : <ExpandMoreIcon sx={{ color: '#fff' }} />}
        </IconButton>
      </FaqTitle>
      <Collapse in={open}>
        <FaqContent>{content}</FaqContent>
      </Collapse>
    </FaqItemContainer>
  );
};

// FAQ Section
const FaqSection: React.FC<{ questions: { title: string, content: React.ReactElement }[] }> = ({ questions }) => {
  return (
    <LayoutContainer>
      <Box p={4}>
        <Typography variant="h4" gutterBottom align="center" pb={4}>
          Frequently Asked Questions
        </Typography>
        {questions.map((question, index) => (
          <FaqQuestion key={index} title={question.title} content={question.content} />
        ))}
      </Box>
    </LayoutContainer>
  );
};

export default FaqSection;
