// src/context/AuthContext.tsx
import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import axios from 'axios';

type User = {
  id: number;
  username: string;
  discord_alias: string;
  role: string;
  email: string;
  discord_uid: string;
  date_joined: string;
};

type AuthContextType = {
  user: User | null;
  login: (userDetails: User) => void;
  logout: () => void;
  loading: boolean;
};

interface CustomWindow extends Window {
  bloomies?: { user: User };
}

declare let window: CustomWindow;

const AuthContext = createContext<AuthContextType | undefined>(undefined);

const FIVE_MINUTES = 5 * 60 * 1000; // 5 minutes in milliseconds

export const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  // Set the cached user from localStorage or window.bloomies
  const authCache = localStorage.getItem('auth');
  const bloomiesUser = window.bloomies?.user ?? (authCache ? JSON.parse(authCache) : null);
  const [user, setUser] = useState<User | null>(bloomiesUser);
  const [loading, setLoading] = useState<boolean>(true); // Always set loading to true initially

  const checkAuth = () => {
    const lastChecked = localStorage.getItem('lastAuthCheck');
    const currentTime = new Date().getTime();

    // If last check was more than 5 minutes ago, or if there is no record of last check
    if (!lastChecked || currentTime - parseInt(lastChecked, 10) > FIVE_MINUTES) {
      setLoading(true); // Set loading while the API call is in progress
      axios
        .get('/api/users/current')
        .then((response) => {
          localStorage.setItem('auth', JSON.stringify(response.data));
          setUser(response.data);
          localStorage.setItem('lastAuthCheck', currentTime.toString()); // Update last checked time
        })
        .catch(() => {
          localStorage.removeItem('auth');
          setUser(null);
        })
        .finally(() => {
          setLoading(false); // Once the fetch is complete, stop loading
        });
    } else {
      setLoading(false); // If it's within the 5-minute window, stop loading
    }
  };

  useEffect(() => {
    // Check authentication status based on last checked time, even if there's a cached user
    checkAuth();
  }, []);

  const login = (userDetails: User) => {
    setUser(userDetails);
    localStorage.setItem('auth', JSON.stringify(userDetails)); // Cache user in localStorage
    localStorage.setItem('lastAuthCheck', new Date().getTime().toString()); // Update last checked time
    window.bloomies = { user: userDetails }; // Cache in global window object
  };

  const logout = () => {
    setUser(null);
    localStorage.removeItem('auth');
    localStorage.removeItem('lastAuthCheck'); // Clear last checked timestamp
    delete window.bloomies;
  };

  return (
    <AuthContext.Provider value={{ user, login, logout, loading }}>
      {!loading && children} {/* Ensure children are only rendered when loading is complete */}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
