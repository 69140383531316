// src/App.tsx
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import NavigationBar from './components/NavigationBar';
import HomePage from './pages/PageHome';
import ProfilePage from './pages/PageProfile';
import CommunityPage from './pages/PageCommunity';
import PageAuth from './pages/PageAuth';
import NotFoundPage from './pages/PageNotFound';
import ProtectedRoute from './components/ProtectedRoute';
import PageComingSoon from './pages/PageComingSoon';
import PagePartner from './pages/PagePartner';
import DevleopmentPage from './pages/PageInDevelopment';
import PageEcho from './pages/PageEcho';
import PageAuthSuccess from './pages/PageAuthSuccess';
import PageSettings from './pages/PageSettings';
import EveNavigationBar from './eve-components/EveNavigationBar';
import EveHomePage from './eve-pages/EvePageHome';
import PageCreateKnowledgebaseArticle from './eve-pages/PageEveKnowledgebaseArticleCreate';
import ArticlePage from './eve-pages/PageEveKnowledgebaseArticle';
import PageEveKnowledgebaseCategory from './eve-pages/PageEveKnowledgebaseCategory';
import PageKnowledgebaseHome from './eve-pages/PageEveKnowledgebaseHome';
import PageCreateQuizQuestion from './eve-pages/PageEveCreateQuizQuestion';
import PageQuizQuestions from './eve-pages/PageEveQuizQuestions';
import PageQuizSessions from './eve-pages/PageEveQuizSessions';
import PageCreateQuizSession from './eve-pages/PageEveQuizCreateSession';
import PageQuizResults from './eve-pages/PageEveQuizResults';
import PageQuizEngine from './eve-pages/PageEveQuizEngine';
import PageEtymologies from './eve-pages/PageEveEtymologies';
import PageCreateEtymology from './eve-pages/PageEveCreateEtymology';
import PageSuggestEtymology from './eve-pages/PageEveSuggestEtymologies';
import PageEveJoinUs from './eve-pages/PageEveJoinUs';
import MemberServicesPage from './pages/PageMemberServices';
import AuditWizard from './services/PageServiceEveAudit';

const App: React.FC = () => {
    let isEveSubdomain = window.location.host.toLowerCase() == "eve.everbloom.cloud" || localStorage.getItem('eve') != null;
    if (isEveSubdomain) {
        return (
            <div>
                <EveNavigationBar />
                <Routes>
                    <Route path="/auth" element={<PageAuth />} />
                    <Route path="/auth/success" element={<PageAuthSuccess />} />
                    <Route path="/" element={<ProtectedRoute><EveHomePage /></ProtectedRoute>} />
                    <Route path="/join" element={<PageEveJoinUs />} />
                    <Route path="/learn" element={<PageKnowledgebaseHome />} />
                    <Route path="/learn/new" element={<PageCreateKnowledgebaseArticle />} />
                    <Route path="/learn/categories/:category" element={<PageEveKnowledgebaseCategory />} />
                    <Route path="/learn/edit/*" element={<PageCreateKnowledgebaseArticle />} />


                    <Route path="/services" element={<MemberServicesPage/>}/>
                    <Route path="/services/audit" element={<AuditWizard/>}/>
               
                    <Route path="/quiz/questions" element={<PageQuizQuestions/>}/>
                    <Route path="/quiz/questions/new" element={<PageCreateQuizQuestion/>}/>
                    <Route path="/quiz/questions/edit/:id" element={<PageCreateQuizQuestion/>}/>
                    <Route path="/quiz/sessions" element={<PageQuizSessions/>}/>
                    <Route path="/quiz/sessions/new" element={<PageCreateQuizSession/>}/>
                    <Route path="/quiz/results/:token" element={<PageQuizResults/>}/>
                    <Route path="/quiz/:token" element={<PageQuizEngine/>}/>
                    
                    <Route path="/learn/etymologies" element={<PageEtymologies />} />
                    <Route path="/learn/etymologies/new" element={<PageCreateEtymology />} />
                    <Route path="/learn/etymologies/edit/:id" element={<PageCreateEtymology />} />
                    <Route path="/learn/etymologies/suggest/:articleId" element={<PageSuggestEtymology/>} />

                    <Route path="/learn/*" element={<ArticlePage />} />

                    <Route path="/profile" element={<ProtectedRoute><DevleopmentPage /></ProtectedRoute>} />
                    <Route path="/settings/*" element={<ProtectedRoute><PageSettings /></ProtectedRoute>} />
                    <Route path="/profile/:username" element={<ProtectedRoute><ProfilePage /></ProtectedRoute>}>
                        <Route path="bio" element={<ProfilePage />} />
                        <Route path="friends" element={<ProfilePage />} />
                        <Route path="inventory" element={<ProfilePage />} />
                        <Route path="achievements" element={<ProfilePage />} />
                        <Route path="wardrobe" element={<ProfilePage />} />
                    </Route>
                </Routes>
            </div>
        );
    }
    return (
        <div>
            <NavigationBar />
            <Routes>
                <Route path="/auth" element={<PageAuth />} />
                <Route path="/auth/success" element={<PageAuthSuccess />} />
                <Route path="/" element={<ProtectedRoute><HomePage /></ProtectedRoute>} />
                <Route path="/profile/:username" element={<ProtectedRoute><ProfilePage /></ProtectedRoute>}>
                    <Route path="bio" element={<ProfilePage />} />
                    <Route path="friends" element={<ProfilePage />} />
                    <Route path="inventory" element={<ProfilePage />} />
                    <Route path="achievements" element={<ProfilePage />} />
                    <Route path="wardrobe" element={<ProfilePage />} />
                </Route>
                <Route path="/echo/:slug" element={<ProtectedRoute><PageEcho /></ProtectedRoute>} />
                <Route path="/profile" element={<ProtectedRoute><DevleopmentPage /></ProtectedRoute>} />
                <Route path="/shop" element={<ProtectedRoute><DevleopmentPage /></ProtectedRoute>} />
                <Route path="/register" element={<ProtectedRoute><DevleopmentPage /></ProtectedRoute>} />
                <Route path="/community" element={<ProtectedRoute><DevleopmentPage /></ProtectedRoute>} />
                <Route path="/community/*" element={<ProtectedRoute><CommunityPage /></ProtectedRoute>} />
                <Route path="/partners/*" element={<ProtectedRoute><PagePartner /></ProtectedRoute>} />
                <Route path="/apps" element={<ProtectedRoute><DevleopmentPage /></ProtectedRoute>} />
                <Route path="/settings/*" element={<ProtectedRoute><PageSettings /></ProtectedRoute>} />
                <Route path="*" element={<NotFoundPage />} />
            </Routes>
        </div>
    );
};

export default App;
