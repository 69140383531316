import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, Typography, Button, CircularProgress, Grid } from '@mui/material';
import LayoutContainer from '../components/LayoutContainer';
import { useAuth } from '../context/AuthContext';
import ReactMarkdown from 'react-markdown';
import { FastAverageColor } from 'fast-average-color';
import CategoryIcon from '../eve-components/CategoryIcon';
import InfoBox from '../components/InfoBox';
import { Build, Info, School, Warning } from '@mui/icons-material';
import EtymologyMarkdown from '../eve-components/EtymologyMarkdown';

const ArticlePage: React.FC = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { user } = useAuth();

    const [article, setArticle] = useState<any>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [etymologies, setEtymologies] = useState<{}[]>([]);
    const [error, setError] = useState<string | null>(null);
    const [bgColor, setBgColor] = useState<string>('#121212');
    const [bgUrl, setBgUrl] = useState<string>('');

    const slug = location.pathname.replace('/learn/', '');

    useEffect(() => {
        const fetchArticle = async () => {
            try {
                setLoading(true);
                const response = await fetch(`/api/kb/${slug}`);
                if (!response.ok) {
                    throw new Error('Article not found');
                }
                const data = await response.json();
                setArticle(data);
                setEtymologies(data.etymologies ?? []);

                // Calculate the average color of the background image
                if (data.background_image_url) {
                    const fac = new FastAverageColor();
                    setBgUrl(data.background_image_url);
                    fac.getColorAsync(data.background_image_url)
                        .then((color) => {
                            setBgColor(color.hex);
                        })
                        .catch((err) => {
                            console.error('Error calculating average color:', err);
                            setBgColor('#000'); // Fallback color
                        });
                }
            } catch (err: any) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchArticle();
    }, [slug]);

    if (loading) {
        return (
            <Box sx={{ textAlign: 'center', marginTop: '20vh' }}>
                <CircularProgress />
            </Box>
        );
    }

    if (error) {
        return (
            <Box sx={{ textAlign: 'center', marginTop: '20vh' }}>
                <Typography variant="h4" color="error">
                    {error}
                </Typography>
            </Box>
        );
    }

    const renderInfoBox = () => {
        if (article.content_classification === 'BEGINNER') {
            return (
                <InfoBox
                    icon={School}
                    title="Beginner's Guide"
                    text="This article is intended for those new to EVE Online. It may lack depth compared to more advanced guides."
                    backgroundColor="#3e5e83" // Custom background color
                />

            );
        }

        if (article.content_classification === 'ADVANCED') {
            return (
                <InfoBox
                    icon={Build}
                    title="Advanced Guide"
                    text="This article is not intended for players new to EVE Online and covers advanced concepts that might be overwhelming."
                    backgroundColor="#6f4b2c" // Custom background color
                />

            );
        }

        if (article.content_classification === 'OPINION') {
            return (
                <InfoBox
                    icon={Info}
                    title="Opinion Piece"
                    text="This article is not educational and shouldn't regarded as factual, it is the opinion of the author."
                    backgroundColor="#6a5487" // Custom background color
                />

            );
        }


        if (article.content_classification === 'OTHER') {
            return (
                <InfoBox
                    icon={Info}
                    title="Non-Educational Content"
                    text="This article has been classified as non-educational content and might not be relevant if you're looking for general EVE advice."
                    backgroundColor="#774873" // Custom background color
                />

            );
        }


        if (article.content_classification === 'WORK_IN_PROGRESS') {
            return (
                <InfoBox
                    icon={Warning}
                    title="Work in Progress"
                    text="This article is still under development and may be incomplete or subject to changes."
                />
            );
        }

        // Add more classifications here as needed

        return null;
    };

    return (
        <Box component="section" sx={{ backgroundColor: bgColor, color: '#fff', position: 'relative', minHeight: '100vh', padding: 4 }}>
            <Box component="div" style={{ left: 0, right: 0, top: -20, height: '115vh', position: 'absolute', overflow: 'hidden', }}>
                <img style={{ width: '100%', height: '110vh', objectFit: 'cover', objectPosition: 'center', filter: 'blur(3px)' }} src={bgUrl} />
            </Box>
            <Box component="div" style={{ position: 'absolute', left: 0, right: 0, top: 0, height: '115vh', background: `linear-gradient(0deg, ${bgColor} 0%, transparent 100%)` }}>

            </Box>
            <Box component="div" style={{ position: 'absolute', left: 0, right: 0, top: 0, height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.2)' }}>

            </Box>
            <Box sx={{ paddingTop: '128px', position: 'relative', filter: 'drop-shadow(0px 5px 12px rgba(0,0,0,0.9))' }}>
                <Box component="div" style={{ position: 'absolute', left: 50, right: 50, top: 400, height: 300, background: `linear-gradient(0deg, rgba(0,0,0,0.2) 0%, transparent 100%)` }}>

                </Box>
                <Box component="div" style={{ position: 'absolute', left: 50, right: 50, top: 700, bottom: -20, backgroundColor: 'rgba(0, 0, 0, 0.2)' }}>

                </Box>
                <LayoutContainer>
                    <Box sx={{ margin: '100px auto', textAlign: 'center', textShadow: '0px 2px 3px #000', position: 'relative' }}>
                        <Typography variant="h2" sx={{ mb: 2, textAlign: 'left' }}>
                            {article.title}
                        </Typography>

                        {/* Categories Line */}
                        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2,  zoom: 1.5 }}>
                            {article.main_category && (
                                <Box sx={{ mr: 2 }}>
                                    <CategoryIcon category={article.main_category} />
                                </Box>
                            )}
                            {article.sub_category && (
                                <Box sx={{ mr: 2 }}>
                                    <CategoryIcon category={article.sub_category} />
                                </Box>
                            )}
                            {article.article_category && (
                                <Box sx={{ mr: 2 }}>
                                    <CategoryIcon category={article.article_category} />
                                </Box>
                            )}
                        </Box>

                        <Typography variant="body2" sx={{ mb: 10, textAlign: 'left' }}>
                            Estimated reading time: {Math.ceil(article.content_md.length / 800)} minute{Math.ceil(article.content_md.length / 1000) > 1 ? 's' : ''}
                        </Typography>

                        {renderInfoBox()}


                        <Box sx={{ textAlign: 'left', zoom: 1.25 }}>
                            <Typography variant="body1">
                                <EtymologyMarkdown content={article.content_md} etymologies={etymologies} />
                            </Typography>
                        </Box>

                        <Box sx={{ mt: 6, textAlign: 'left' }}>
                            <Typography variant="body2">
                                Edited by: {article.created_by}
                            </Typography>
                            <Typography variant="body2">
                                Last updated: {new Date(article.updated_at).toLocaleDateString()}
                            </Typography>
                        </Box>

                        {user && (
                            <Box sx={{ mt: 4 }}>
                                <Grid container spacing={2} justifyContent="center">
                                    <Grid item xs={12} sm={4}>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            fullWidth
                                            onClick={() => navigate(`/learn/edit/${slug}`)}
                                        >
                                            Edit Article
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            fullWidth
                                            onClick={() => navigate(`/quiz/questions/new?relatedid=${article.id}`)}
                                        >
                                            Create Quiz Question
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            fullWidth
                                            onClick={() => navigate(`/learn/etymologies/suggest/${article.id}`)}
                                        >
                                            Suggest Etymologies
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Box>
                        )}

                    </Box>
                </LayoutContainer>
            </Box>
        </Box>
    );
};

export default ArticlePage;
