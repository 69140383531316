import { Grid, Box, Typography, Button } from '@mui/material';
import LayoutContainer from '../components/LayoutContainer';
import { EverbloomPost, EverbloomPostType } from '../types/EverbloomUpdateEntry';
import EverbloomUpdateEntryComponent from '../components/EverbloomUpdateEntry';
import { Newspaper } from '@mui/icons-material';

const EveWhatsNewSection: React.FC = () => {
    const newsItems: EverbloomPost[] = [];

    return (
        <Box component="section" id="whatsNew" sx={{ position: 'relative' }}>
            <LayoutContainer>
                <Typography variant="h3" sx={{ textAlign: 'center', m: 6, mb: 4, color: 'primary.main' }}>
                    Latest News
                </Typography>
                {newsItems.length == 0 ? <Typography variant="body1" sx={{ textAlign: 'center', m: 6, mb: 4, color: 'primary.main' }}>
                    There are no new items. Check back later!
                </Typography> : null}
                <Grid container spacing={4} padding={4}>
                    {newsItems.map((item, index) => (
                        <Grid item xs={12} md={4} key={item.post_id}>
                            <EverbloomUpdateEntryComponent entry={item} />
                        </Grid>
                    ))}
                </Grid>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'center', // Centers the buttons horizontally
                    mt: 0, // Adds margin top for spacing from text above
                    mb: 4,
                }}>

                    {newsItems.length > 0 && <Button href="/community/latest" variant="contained" sx={{
                        margin: 2,
                        padding: '8px 15px',
                        width: '180px',
                        fontSize: '20px',
                        borderRadius: 0,
                        boxShadow: '0px 2px 2px rgba(0,0,0,0.2)',
                        color: '#fff',
                        backgroundColor: '#222',
                        '&:hover': {
                            backgroundColor: '#e091a2',
                            boxShadow: '0px 0px 2px rgba(0,0,0,0.5)'
                        },
                    }}
                        startIcon={<Newspaper />}>
                        See All News
                    </Button>}
                </Box>

            </LayoutContainer>
        </Box>
    );
};

export default EveWhatsNewSection;
