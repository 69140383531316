import React from 'react';
import { Box, Typography } from '@mui/material';
import { SvgIconComponent } from '@mui/icons-material';

interface InfoBoxProps {
  icon: SvgIconComponent;
  title: string;
  text: string;
  backgroundColor?: string; // Optional background color prop
}

const InfoBox: React.FC<InfoBoxProps> = ({ icon: Icon, title, text, backgroundColor = '#777777CC' }) => {
  return (
    <Box sx={{ backgroundColor: backgroundColor, p: 2, mb: 2, mt: 3 }}>
      <Typography variant="h5" sx={{ mt: 0, display: 'flex', alignItems: 'center', color: '#fff' }}>
        <Icon sx={{ mr: 1 }} />
        {title}
      </Typography>
      <Typography variant="body2" sx={{ mt: 1, color: '#fff', textAlign: 'left' }}>
        {text}
      </Typography>
    </Box>
  );
};

export default InfoBox;
