// Define a mapping of categories to icon URLs
const iconMap: { [key: string]: string } = {
    industry: '/eve/Icons/items/18_128_2.png',
    mining: '/eve/Icons/items/12_64_8.png',
    neutralizers: '/eve/Icons/items/12_64_4.png',
    webifiers: '/eve/Icons/items/12_64_6.png',
    cpu: '/eve/Icons/items/12_64_7.png',
    drone_bay: '/eve/Icons/items/11_64_16.png',
    missiles: '/eve/Icons/items/12_64_12.png',
    market: '/eve/Icons/items/33_128_2.png',
    implants: '/eve/Icons/items/31_64_16.png',
    blasters: '/eve/Icons/items/13_64_1.png',
    railguns: '/eve/Icons/items/13_64_5.png',
    disintegrators: '/eve/Icons/items/Modules/disintegratorCannonS.png',
    generic: '/eve/Icons/items/33_128_3.png',
    lasers: '/eve/Icons/items/13_64_13.png',
    projectiles: '/eve/Icons/items/12_64_9.png',
    drones: '/eve/Icons/items/105_32_48.png',
    burst_projectors: '/eve/Icons/items/Modules/burstProjectorECM.png',
    mutations: '/eve/Icons/items/Modules/mutatorGravid.png',
    interdiction: '/eve/Icons/items/118_64_2.png',
    security: '/assets/icons/security.png',
    mail: '/eve/Icons/items/12_64_1.png',
    rigs: '/eve/Icons/items/68_64_10.png',
    salvage: '/eve/Icons/items/69_64_11.png',
    gas: '/eve/Icons/items/62_64_10.png',
    boosters: '/eve/Icons/items/62_64_6.png',
    manufacturing: '/eve/Icons/items/55_64_3.png',
    ice: '/eve/Icons/items/51_64_5.png',
    strip_miner: '/eve/Icons/items/49_64_5.png',
    tritanium: '/eve/Icons/items/6_64_14.png',
    scrambler: '/eve/Icons/items/4_64_9.png',
    fuel: '/eve/Icons/items/ComprFuel_Amarr.png',
    pi: '/eve/Icons/items/96_64_10.png',
    crystals: '/eve/Icons/items/8_64_5.png',
    alliance: '/eve/Icons/items/7_64_6.png',
    wallet: '/eve/Icons/items/7_64_12.png',
    slot_high: '/eve/Icons/items/8_64_11.png',
    slot_medium: '/eve/Icons/items/8_64_10.png',
    slot_low: '/eve/Icons/items/8_64_9.png',
    hauling: '/eve/Icons/items/7_64_16.png',
    injectors: '/eve/Icons/items/skillInjector_64.png',
    plex: '/eve/Icons/items/plex.png',
    shields: '/eve/Icons/items/dust_icon_mod_shield_booster.png',
    armor: '/eve/Icons/items/dust_icon_mod_armor_armorrepair.png',
    hacking: '/eve/Icons/items/dust_icon_mod_codebreaker.png',
    relic: '/eve/Icons/items/dust_icon_mod_codebreaker.png',
    bombs: '/eve/Icons/items/70_64_5.png',
    compression: '/eve/Icons/items/71_64_1.png',
    gift: '/eve/Icons/items/76_64_3.png',
    warp_core: '/eve/Icons/items/76_64_16.png',
    piracy: '/eve/Icons/items/4_64_7.png',
    ecm: '/eve/Icons/items/4_64_12.png',
    dampeners: '/eve/Icons/items/4_64_11.png',
    ammo: '/eve/Icons/items/5_64_2.png',
    isogen: '/eve/Icons/items/6_64_16.png',
    pyerite: '/eve/Icons/items/6_64_15.png',
    mexallon: '/eve/Icons/items/6_64_12.png',
    doomsday: '/eve/Icons/items/56_64_2.png',
    contracts: '/eve/Icons/items/64_64_10.png',
    auctions: '/eve/Icons/items/64_64_16.png',
    nanite: '/eve/Icons/items/70_64_12.png',
    survey: '/eve/Icons/items/3_64_12.png',
    inertia: '/eve/Icons/items/3_64_6.png',
    resolution: '/eve/Icons/items/3_64_9.png',
    propulsion: '/eve/Icons/items/3_64_2.png',
    cap_boosters: '/eve/Icons/items/1_64_6.png',
    cap_batteries: '/eve/Icons/items/1_64_4.png',
    nosferatu: '/eve/Icons/items/1_64_3.png',
    cap_relay: '/eve/Icons/items/1_64_1.png',
    nanofibre: '/eve/Icons/items/2_64_9.png',
    remote_reps: '/eve/Icons/items/1_64_16.png',
    armor_plating: '/eve/Icons/items/1_64_10.png',
    probes: '/eve/Icons/items/27_64_9.png',
    combat_probes: '/eve/Icons/items/34_64_1.png',
    scanning: '/eve/Icons/items/107_64_9.png',
    grapplers: '/eve/Icons/items/125_64_1.png',
    filaments: '/eve/Icons/items/Inventory/abyssalFilamentL2.png',
    salvaging: '/eve/Icons/items/64_64_11.png',
    shield_extender: '/eve/Icons/items/dust_icon_mod_shield_shieldextender.png',
    ship_scanning: '/eve/Icons/items/dust_icon_mod_unit_scanner.png',
    shield_hardener: '/eve/Icons/items/dust_icon_mod_shield_shieldhardener.png',
    shield_recharger: '/eve/Icons/items/dust_icon_mod_shield_shieldrecharger.png',
    skills: '/eve/Icons/items/dust_icon_skill.png',
    pg: '/eve/Icons/items/dust_icon_mod_eng_reactorcoreunit.png',
    powergrid: '/eve/Icons/items/dust_icon_mod_eng_reactorcoreunit.png',

    amarr: '/eve/Icons/items/27_64_12.png',
    gallente: '/eve/Icons/items/23_64_5.png',

    container: '/eve/Icons/items/27_64_12.png',
    arkonor: '/eve/Icons/items/23_64_5.png',
    omber: '/eve/Icons/items/23_64_13.png',
    plagioclase: '/eve/Icons/items/24_64_2.png',
    ideas: '/eve/Icons/items/25_64_6.png',
    veldspar: '/eve/Icons/items/24_64_1.png',
    combat: '/eve/Icons/items/50_64_15.png',
    settings: '/eve/Icons/items/74_64_15.png',
    tractor: '/eve/Icons/items/56_64_4.png',
    death: '/eve/Icons/items/4_64_16.png',
    medals: '/eve/Icons/items/45_64_16.png',
    reactions: '/eve/Icons/items/47_64_3.png',
    blueprints: '/eve/Icons/items/57_64_9.png',
    loyalty: '/eve/Icons/items/70_64_11.png',
    policy: '/eve/Icons/items/39_64_16.png',
    player_development: '/eve/Icons/items/multiple_training.png',
    assets: '/eve/Icons/items/7_64_13.png',
    undocking: '/eve/Icons/items/9_64_6.png',
    guides: '/eve/Icons/items/6_64_1.png',
    announcements: '/eve/Icons/items/6_64_7.png',
    everbloom_company: '/assets/icons/corp.png',
    official: '/assets/icons/official.png',
    // Add more categories as needed
};

// Function to get the icon URL based on the category name
export const getCategoryIcon = (category: string): string => {
    const key = category.toLowerCase().replace(' ', '_');
    return iconMap[key] ? 'https://cdn.everbloom.cloud' + iconMap[key] : '/assets/icons/default.png'; // Return a default icon if the category is not found
};
