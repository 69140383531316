import React, { useState, useEffect } from 'react';
import { Box, Button, CircularProgress, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

interface QuizSession {
  id: number;
  token: string;
  in_game_name: string;
  difficulty: string;
  state: string;
  quiz_created_at: string;
}

const PageQuizSessions: React.FC = () => {
  const [sessions, setSessions] = useState<QuizSession[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();

  // Fetch sessions on component mount
  useEffect(() => {
    const fetchSessions = async () => {
      try {
        const response = await fetch('/api/quiz/sessions');
        if (!response.ok) {
          throw new Error('Failed to fetch sessions');
        }
        const data = await response.json();
        setSessions(data);
        setLoading(false);
      } catch (err) {
        setError('Failed to load quiz sessions.');
        setLoading(false);
      }
    };

    fetchSessions();
  }, []);

  const handleCreateNewSession = () => {
    navigate('/quiz/sessions/new');
  };

  if (loading) {
    return (
      <Box sx={{ textAlign: 'center', marginTop: '20vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ textAlign: 'center', marginTop: '20vh' }}>
        <Typography color="error">{error}</Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ padding: '32px' }}>
      <Typography variant="h4" sx={{ mb: 4 }}>
        Quiz Sessions
      </Typography>

      <Button
        variant="contained"
        color="primary"
        sx={{ mb: 4 }}
        onClick={handleCreateNewSession}
      >
        Create New Session
      </Button>

      <Table>
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>Token</TableCell>
            <TableCell>In-Game Name</TableCell>
            <TableCell>Difficulty</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Created At</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sessions.map((session) => (
            <TableRow key={session.id}>
              <TableCell>{session.id}</TableCell>
              <TableCell>{session.token}</TableCell>
              <TableCell>{session.in_game_name}</TableCell>
              <TableCell>{session.difficulty}</TableCell>
              <TableCell>{session.state}</TableCell>
              <TableCell>{new Date(session.quiz_created_at).toLocaleString()}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  );
};

export default PageQuizSessions;
