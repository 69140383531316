import React, { useState, useEffect, ReactNode } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { Box, Button, CircularProgress, TextField, Typography, Grid, FormControl, Select, MenuItem, InputLabel, SelectChangeEvent } from '@mui/material';
import LayoutContainer from '../components/LayoutContainer';
import KnowledgebaseAutocomplete from '../eve-components/KnowledgebaseAutocomplete';

const etymologyCategories = [
    'SHIP',
    'MODULE',
    'ITEM',
    'SOLAR_SYSTEM',
    'PLAYER_CORPORATION',
    'NPC_CORPORATION',
    'GAME_MECHANIC',
    'TACTIC',
    'PLAYER',
    // Add more as needed
];

const PageCreateEtymology: React.FC = () => {
    const navigate = useNavigate();
    const { id } = useParams<{ id: string }>(); // For edit mode
    const location = useLocation(); // To get the query string
    const editMode = !!id; // If ID exists, we are in edit mode
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);

    const [formData, setFormData] = useState({
        name: '',
        short_description: '',
        icon_url: '',
        image_url: '',
        category: '',
        aliases: '',
        related_item: -1, // KnowledgebaseItem id
    });

    // Fetch etymology data if in edit mode
    useEffect(() => {
        if (editMode) {
            const fetchEtymology = async () => {
                try {
                    setLoading(true);
                    const response = await fetch(`/api/etymologies/${id}`);
                    const etymology = await response.json();
                    setFormData({
                        name: etymology.name,
                        short_description: etymology.short_description,
                        icon_url: etymology.icon_url || '',
                        image_url: etymology.image_url || '',
                        category: etymology.category || '',
                        related_item: etymology.knowledgebaseItemId || -1,
                        aliases: etymology.aliases || '',
                    });
                    setLoading(false);
                } catch (err) {
                    setError('Failed to fetch etymology.');
                    setLoading(false);
                }
            };
            fetchEtymology();
        }
    }, [editMode, id]);

    // Read query string to check for relatedid
    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const relatedId = queryParams.get('relatedid');
        if (relatedId) {
            setFormData((prev) => ({
                ...prev,
                related_item: parseInt(relatedId, 10),
            }));
        }
    }, [location.search]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSelectChange = (event: SelectChangeEvent<string>, child: ReactNode) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value
        });
    }

    const handleSubmit = async () => {
        setLoading(true);
        setError(null);

        try {
            const method = editMode ? 'PUT' : 'POST';
            const url = editMode ? `/api/etymologies/${id}` : '/api/etymologies';

            if (formData.related_item === -1) {
                setError('The related knowledgebase item must be provided.');
                setLoading(false);
                return;
            }

            const response = await fetch(url, {
                method,
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    ...formData,
                    knowledgebaseItemId: formData.related_item,
                }),
            });

            const result = await response.json();

            if (!response.ok) {
                setError(result.errors ? result.errors.map((e: any) => e.msg).join('\n\n') : `Failed to ${editMode ? 'update' : 'create'} etymology.`);
                setLoading(false);
                return;
            }

            setLoading(false);
            navigate(`/etymologies`); // Navigate to the etymology list or another appropriate page
        } catch (err) {
            console.error('Error submitting etymology:', err);
            setError(`Failed to ${editMode ? 'update' : 'create'} etymology. Please try again.`);
            setLoading(false);
        }
    };

    return (
        <Box component="section" sx={{ position: 'relative', mt: 8 }}>
            <LayoutContainer>
                <Typography variant="h4" sx={{ textAlign: 'center', mt: 4 }}>
                    {editMode ? 'Edit' : 'Create'} Etymology
                </Typography>

                <Box sx={{ margin: '0 auto', mt: 4 }}>
                    {error && <Typography color="error">{error}</Typography>}

                    {/* Name */}
                    <FormControl fullWidth margin="normal">
                        <TextField
                            label="Name"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                            required
                        />
                    </FormControl>

                    {/* Short Description */}
                    <FormControl fullWidth margin="normal">
                        <TextField
                            label="Aliases (comma seperated)"
                            name="aliases"
                            value={formData.aliases}
                            onChange={handleChange}
                            required
                        />
                    </FormControl>

                    {/* Short Description */}
                    <FormControl fullWidth margin="normal">
                        <TextField
                            label="Short Description"
                            name="short_description"
                            value={formData.short_description}
                            onChange={handleChange}
                            required
                        />
                    </FormControl>

                    {/* Category */}
                    <FormControl fullWidth margin="normal">
                        <InputLabel>Category</InputLabel>
                        <Select
                            name="category"
                            value={formData.category}
                            onChange={handleSelectChange}
                            required
                        >
                            {etymologyCategories.map((category) => (
                                <MenuItem key={category} value={category}>
                                    {category.replace('_', ' ').toLowerCase().replace(/\b\w/g, l => l.toUpperCase())}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    {/* Related Knowledgebase Item */}
                    <FormControl fullWidth margin="normal">
                        <KnowledgebaseAutocomplete
                            onSelect={(id: number | null) => {
                                if (id != null) {
                                    setFormData({ ...formData, related_item: id })
                                }
                            }}
                        />
                    </FormControl>

                    {/* Media URLs */}
                    <Grid container spacing={2} marginBottom={2}>
                        <Grid item xs={6}>
                            <FormControl fullWidth margin="normal">
                                <TextField
                                    label="Icon URL"
                                    name="icon_url"
                                    value={formData.icon_url}
                                    onChange={handleChange}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth margin="normal">
                                <TextField
                                    label="Image URL"
                                    name="image_url"
                                    value={formData.image_url}
                                    onChange={handleChange}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>

                    <Button
                        variant="contained"
                        color="primary"
                        sx={{ mt: 4 }}
                        onClick={handleSubmit}
                        disabled={loading}
                    >
                        {loading ? <CircularProgress size={24} /> : editMode ? 'Update Etymology' : 'Create Etymology'}
                    </Button>
                </Box>
            </LayoutContainer>
        </Box>
    );
};

export default PageCreateEtymology;
