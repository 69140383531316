import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, CircularProgress, Typography, Paper, Link } from '@mui/material';
import LayoutContainer from '../components/LayoutContainer';
import { getQuizSession } from './PageEveQuizEngine';
import HeroJoinUsSection from '../eve-components/HeroJoinUs';
import EveBenefitsSection, { EveBenefit } from '../eve-components/EveBenefitsSection';
import { Star, LocalShipping, MonetizationOn, ShoppingCart, Group, School } from '@mui/icons-material';
import EveArticleSection from '../eve-components/EveArticleSection';
import PlayerExperienceForm from '../eve-components/EveApplyCorpSection';
import FaqSection from '../eve-components/FaqSection';

const benefits: EveBenefit[] = [
    {
        image: 'https://cdn.everbloom.cloud/eve/promo/benefits_reel_welcome_bonus.jpg',
        icon: <Star />,
        heading: 'Welcome Bonuses',
        learnMoreAction: 'Our Benefits',
        learnMoreLink: 'https://eve.everbloom.cloud/learn/everbloom-company/benefits/bloomies-onboarding-programme',
        description:
            "To get you started on the right foot, we've designed a three-milestone onboarding program that equips you with essential resources and rewards you for your activity and commitment.",
    },
    {
        image: 'https://cdn.everbloom.cloud/eve/promo/benefits_reel_srp.jpg',
        icon: <LocalShipping />,
        learnMoreLink: 'https://eve.everbloom.cloud/learn/everbloom-company/policies/ship-replacement-program-policy',
        learnMoreAction: 'Policy Details',
        heading: 'Ship Replacement Programme',
        description:
            "We provide additional compensation for the loss of many ship hulls, our policy is very generous, empowering you to choose your own path with our support.",
    },
    {
        image: 'https://cdn.everbloom.cloud/eve/promo/benefits_reel_automated_reward.jpg',
        icon: <MonetizationOn />,
        heading: 'Additional ISK Payouts for PvE',
        learnMoreLink: 'https://eve.everbloom.cloud/learn/everbloom-company/benefits/bloomies-opportunity-rewards',
        learnMoreAction: 'How it works',
        description:
            "We pay out ISK bonuses using the Projects tool for simply completing missions, mining ore, fighting rats and players in our home systems to enrich whichever content you decide to pursue.",
    },
    {
        image: 'https://cdn.everbloom.cloud/eve/promo/benefits_reel_discounted_fits.jpg',
        icon: <ShoppingCart />,
        heading: 'Discounted fits available',
        learnMoreLink: 'https://eve.everbloom.cloud/learn/everbloom-company/benefits/discounted-fits',
        learnMoreAction: 'Learn more',
        description:
            "Commonly used ship fits geared towards exploration, mining, PvP and missions are available below market rates for our corp members.",
    },
    {
        image: 'https://cdn.everbloom.cloud/eve/promo/benefits_reel_mining.jpg',
        icon: <Group />,
        heading: 'Regular fleet activities',
        learnMoreLink: 'https://eve.everbloom.cloud/learn/everbloom-company/benefits/everbloom-fleet-activities',
        learnMoreAction: 'Discover more',
        description:
            "We run fleets for varying experience levels, from faction warfare fleets, mining ops and roams for both new and experienced players.",
    },
    {
        image: 'https://cdn.everbloom.cloud/eve/promo/benefits_reel_mentorship2.jpg',
        icon: <School />,
        heading: 'Mentorship and guidance',
        learnMoreLink: 'https://eve.everbloom.cloud/learn/everbloom-company/benefits/mentorship-program',
        learnMoreAction: 'More details',
        description:
            "Our corporation is comprised of both experienced veterans and new players alike, with mentorship and guidance available both via this website, maintained by our members - and in-game.",
    },
];

const FooterSection: React.FC = () => {
    return (
        <LayoutContainer>
            <Box sx={{ textAlign: 'center', padding: 4 }}>
                <Typography variant="body2" color="#888">
                This domain is part of the Bloomies network and is not affiliated with CCP Games.
                </Typography>
                <Typography variant="body2" color="#888">
                The Everbloom Company is a fictional corporation and any mention of services refers to activities within the EVE Online universe.
                </Typography>
                <Typography variant="body2" color="#888">
                    Bloomies is a platform and brand-concept owned and operated by Everbloom Media Ltd, registered in the UK (Company No. 14974080).
                </Typography>

            </Box>
        </LayoutContainer>
    );
};



const PageEveJoinUs: React.FC = () => {
    return (
        <>
            <HeroJoinUsSection>

            </HeroJoinUsSection>
            <EveBenefitsSection benefits={benefits} />
            <EveArticleSection title={"Our Policies"} fetchUrl='/api/kb/articles/policies'></EveArticleSection>
            <PlayerExperienceForm />
            <FaqSection questions={[{
                title: 'Do I need to provide API/ESI access to join?',
                content: <>
                <Typography variant='body1'><p>No, you don't need to provide API or ESI access to join our corporation.</p></Typography>
                <Typography variant='body1'><p>Some features on our website will work better when you connect your EVE character, but it is not a requirement to join. We may require ESI access for privileged positions such as officer roles.</p></Typography>
                </>
            }, {
                title: 'Is there a required time commitment?',
                content: <>
                <Typography variant='body1'><p>Life comes first, we respect that. You don't have to tell us if you stop playing for a longer period.</p></Typography>
                <Typography variant='body1'><p>You are also not required to participate in corp activities to remain in the corporation.</p></Typography>
                <Typography variant='body1'><p>However, to protect our interests and maintain a safe environment for our players, we may remove your characters from the corporation after <strong>3 months of inactivity</strong>, unless you provide us a valid reason. You will be able to reapply to join once you return to EVE, although we may require an interview if you joined any other corporation since your expulsion.</p></Typography>
                </>
            }, {
                title: 'What timezone does the corporation primarily operate in?',
                content: <>
                <Typography variant='body1'><p>We operate in both EU and US timezones, our leadership comprises of primarily UTC -7 to UTC +2.</p></Typography>
             </>
            }, {
                title: 'Does the corporation participate in wars?',
                content: <>
                <Typography variant='body1'><p>We do not initiate wars, and our structures belong to a holding corp to prevent our members from being harassed.</p></Typography>
             </>
            }, {
                title: 'Can I join if I’m primarily a solo player?',
                content: <>
                <Typography variant='body1'><p>Of course, so long as you stick by our ethos and don't harass or antagonise large groups.</p></Typography>
             </>
            }, {
                title: 'What regions of space does the corp operate in?',
                content: <>
                <Typography variant='body1'><p>Our primary system is Slays in Placid. We have other stations in Irjunen (near Jita) and a C1 wormhole system.</p></Typography>
             </>
            }]}></FaqSection>
            <FooterSection/>
        </>
    );
};

export default PageEveJoinUs;
