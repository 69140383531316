import React, { useState, useEffect } from 'react';
import { Box, Typography, Button, CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import EveCharacterPicker from '../eve-components/EveCharacterPicker';
import LayoutContainer from '../components/LayoutContainer';
import InfoBox from '../components/InfoBox';
import { Security } from '@mui/icons-material';
import { useAuth } from '../context/AuthContext';

const flavorTexts = [
    'Scanning character data...',
    'Checking ship fittings...',
    'Analyzing market transactions...',
    'Reviewing killboard statistics...',
    'Checking corporation history...',
    'Evaluating bookmarks...',
    'Analyzing industry...',
    'Evaluating skills...',
    'Validating corporation history...',
    'Verifying wallet balances...',
];

const AuditWizard: React.FC = () => {
    const { user } = useAuth();
    const [step, setStep] = useState<number>(1);
    const [selectedCharacter, setSelectedCharacter] = useState<any>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [auditResult, setAuditResult] = useState<string | null>(null);
    const [auditResponse, setAuditResponse] = useState<any>({});
    const [flavorText, setFlavorText] = useState<string>(flavorTexts[0]);
    const [fade, setFade] = useState<boolean>(true);
    const navigate = useNavigate();

    const isLoggedIn = user != null; 

    const handleNextStep = () => setStep((prevStep) => prevStep + 1);
    const handlePrevStep = () => setStep((prevStep) => (prevStep > 1 ? prevStep - 1 : 1));

    const handleCharacterSelect = (character: any) => {
        setSelectedCharacter(character);
    };

    if (isLoggedIn === false) {
        return (
            <LayoutContainer>
                <Box sx={{ pt: 10, textAlign: 'center' }}>
                    <InfoBox
                        icon={Security}
                        title="Login Required"
                        text="You must be logged in to access this service. Please log in using Discord."
                        backgroundColor="#892929" // Red background for the error box
                    />
                </Box>
            </LayoutContainer>
        );
    }

    const handleAuditStart = async () => {
        setLoading(true);
        handleNextStep();
        
        try {
            const response = await fetch(`/api/eve/audit/${selectedCharacter.characterID}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (response.status === 401) {
                setAuditResult('unauthorized');
            } else if (response.status === 403) {
                setAuditResult('forbidden');
            } else if (response.status === 201) {
                setAuditResult('success');
                setAuditResponse(await response.json());
            } else {
                setAuditResult('unknown_error');
            }
        } catch (error) {
            setAuditResult('unknown_error');
        } finally {
            setLoading(false);
            handleNextStep();
        }
    };

    // Effect to change flavor text every 2-3 seconds
    useEffect(() => {
        if (!loading) return;

        const interval = setInterval(() => {
            setFade(false); // Start fade out
            setTimeout(() => {
                setFlavorText((prevText) => {
                    const currentIndex = flavorTexts.indexOf(prevText);
                    const nextIndex = (currentIndex + 1) % flavorTexts.length;
                    return flavorTexts[nextIndex];
                });
                setFade(true); // Start fade in
            }, 500); // Wait for fade out to complete before changing text
        }, 2500); // Change text every 2.5 seconds

        return () => clearInterval(interval);
    }, [loading]);

    return (
        <Box sx={{ pt: 10 }}>
            <LayoutContainer>
                {/* Title Section */}
                <Box sx={{ textAlign: 'center', mb: 5 }}>
                    <Box sx={{ backgroundColor: '#252525', p: 3 }}>
                        <Typography variant="h4">
                            {step === 1 && "Step 1: Select Your Character"}
                            {step === 2 && "Step 2: Everbloom Audit Process"}
                            {step === 3 && "Step 3: Running Audit"}
                            {step === 4 && "Audit Complete"}
                        </Typography>
                    </Box>

                    <Box>
                        {/* Content Section */}
                        <Box sx={{ minHeight: '300px', textAlign: 'center', p: 3, backgroundColor: '#181818' }}>
                            {step === 1 && (
                                <>
                                    <EveCharacterPicker onCharacterSelect={handleCharacterSelect} />
                                </>
                            )}

                            {step === 2 && (
                                <>
                                    <Box sx={{ p: 2, maxWidth: '700px', margin: '0 auto' }}>
                                        <Typography variant="body1" sx={{ mt: 2, p: 2, pt: 0 }}>
                                            The audit will gather data on your character's in-game activity for internal evaluation using the EVE API.
                                        </Typography>
                                        <Typography variant="body1" sx={{ mt: 2, p: 2, pt: 0 }}>
                                            This information is strictly confidential and visible only to our officers. Once completed, the leadership team will evaluate the results.
                                        </Typography>
                                        <InfoBox
                                            icon={Security}
                                            title="Why submit an audit?"
                                            text="Unless you're applying for an officer position within our corporation, we will typically never request you complete an audit. Submitting an audit is a way to build trust, but if you've not been requested by an officer to submit an audit, you may not need to proceed."
                                            backgroundColor="#6a5487" // Custom background color
                                        />
                                    </Box>
                                </>
                            )}

                            {step === 3 && (
                                <>
                                    {loading ? (
                                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '200px' }}>
                                            <CircularProgress />
                                            <Typography
                                                variant="body1"
                                                sx={{
                                                    mt: 2,
                                                    opacity: fade ? 1 : 0,
                                                    transition: 'opacity 0.5s ease-in-out',
                                                    color: '#bbb'
                                                }}
                                            >
                                                {flavorText}
                                            </Typography>
                                        </Box>
                                    ) : (
                                        <Typography variant="body1" sx={{ mt: 2 }}>
                                            Audit completed. Click to proceed.
                                        </Typography>
                                    )}
                                </>
                            )}

                            {step === 4 && (
                                <>
                                    {auditResult === 'unauthorized' && (
                                        <Typography variant="body1" sx={{ mt: 2, color: 'red' }}>
                                            You are not logged in with Discord. Please log in and try again.
                                        </Typography>
                                    )}

                                    {auditResult === 'forbidden' && (
                                        <Typography variant="body1" sx={{ mt: 2, color: 'red' }}>
                                            The selected character is not authorized for audit. Please link this character to your account in the settings.
                                        </Typography>
                                    )}

                                    {auditResult === 'success' && (
                                        <>
                                            <Typography variant="body1" sx={{ mt: 2 }}>
                                                Thank you for submitting your audit. A member of the leadership team will review your results.
                                            </Typography>
                                            <Typography variant="body2" sx={{ mt: 2 }}>
                                                Your reference number is <strong>{auditResponse?.auditReference}</strong>.
                                            </Typography>
                                        </>
                                    )}

                                    {auditResult === 'unknown_error' && (
                                        <Typography variant="body1" sx={{ mt: 2, color: 'red' }}>
                                            An unexpected error occurred. Please try again later.
                                        </Typography>
                                    )}
                                </>
                            )}
                        </Box>

                        {/* Action Buttons Section */}
                        <Box sx={{ display: 'flex', justifyContent: 'center', p: 3, backgroundColor: '#212121' }}>
                            {step === 1 && (
                                <Box>
                                    <Button
                                        variant="contained"
                                        sx={{ width: 400 }}
                                        onClick={handleNextStep}
                                        disabled={!selectedCharacter}
                                    >
                                        Confirm and Continue
                                    </Button>
                                </Box>
                            )}
                            {step > 1 && step <= 2 && (
                                <Button variant="contained" sx={{ width: 300, mr: 2 }} onClick={handlePrevStep}>
                                    Back
                                </Button>
                            )}
                            {step === 2 && (
                                <Button variant="contained" sx={{ width: 300, backgroundColor: '#3f5545' }} onClick={handleAuditStart}>
                                    Continue and Consent
                                </Button>
                            )}
                            {step === 3 && !loading && (
                                <Button variant="contained" sx={{ width: 300 }} onClick={handleNextStep}>
                                    Complete
                                </Button>
                            )}
                            {step === 4 && (
                                <Button variant="contained" sx={{ width: 300 }} onClick={() => navigate('/services')}>
                                    Back to Services
                                </Button>
                            )}
                        </Box>
                    </Box>
                </Box>
            </LayoutContainer>
        </Box>
    );
};

export default AuditWizard;
