import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      light: '#534bae',
      main: '#ffffff',
      dark: '#1e0e3a',
      contrastText: '#ffffff',
    },
    secondary: {
      light: '#a0f0f0',
      main: '#75e8e7',
      dark: '#42bfbf',
      contrastText: '#000000',
    },
    error: {
      light: '#ff6666',
      main: '#ff0000',
      dark: '#b20000',
    },
    success: {
      main: '#5bd561',
    },

  },
  typography: {
    fontFamily: '"Rubik", Arial, sans-serif',
    fontSize: 14,
    h1: {
      fontFamily: '"Mochiy Pop One"',
      fontWeight: 700,
      color: '#ffffff'
    },
    h2: {
      fontFamily: '"Mochiy Pop One"',
      fontWeight: 700,
      color: '#ffffff'
    },
    h3: {
      fontFamily: '"Mochiy Pop One"',
      fontWeight: 500,
      fontSize: 30,
      color: '#ffffff'
    },
    h4: {
      fontFamily: '"Mochiy Pop One"',
      fontWeight: 500,
      fontSize: 26,
      color: '#ffffff'
    },
    h5: {
      fontFamily: '"Mochiy Pop One"',
      fontWeight: 400,
      fontSize: 20,
      color: '#eeeeee'
    },
    button: {
      textTransform: 'none',
      fontWeight: 500,
    },
    body1: {
      fontSize: 16,
      color: '#ffffff',
    },
    body2: {
      fontSize: 16,
      textDecoration: 'none',
      color: '#ffffff',
    },
    h6: {
      color: '#ffffff',
    },
  },
  components: {
    MuiTableCell: {
      styleOverrides: {
        root: {

          textAlign: 'center',
          color: '#ffffff', // Ensures text color in table cells is white
          '& .MuiTableCell-root:nth-of-type(0)': {
            textAlign: 'left',
          },
        }
      }
    },
    MuiButton: {
      variants: [
        {
          props: { variant: 'contained' }, // Name of the custom variant
          style: {
            margin: 1,
            padding: '8px 15px',
            width: '180px',
            fontSize: '20px',
            borderRadius: 0,
            boxShadow: '0px 2px 2px rgba(0,0,0,0.2)',
            backgroundColor: '#3d3d3d',
            color: '#ffffff',
            '&:hover': {
              backgroundColor: '#626564',
              boxShadow: '0px 0px 2px rgba(0,0,0,0.5)',
            },
          },
        },
      ],
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: '#111111dd',
          color: 'white',
          backdropFilter: 'blur(5px)',
          boxShadow: '0px 5px 12px #000',
          p: 6,
          fontFamily: '"Rubik", Arial, sans-serif',
          fontSize: 16,
          borderRadius: '8px',
          userSelect: 'none'
        },
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: '#B0B0B0', // Grey border for normal state
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: '#888888', // Darker grey on hover
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#534bae', // Primary color when focused
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          color: '#ffffff', // Make selected text white
        },
        icon: {
          color: '#ffffff', // Dropdown arrow color
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        inputRoot: {
          backgroundColor: '#6b4985', // Purple background for the input
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: '#B0B0B0', // Adjust border color
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: '#888888',
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#534bae',
          },
          '& .MuiInputBase-input': {
            color: '#ffffff', // Input text color
          },
        },
        paper: {
          backgroundColor: '#6b4985', // Purple background for dropdown
          color: '#ffffff', // Text color for dropdown
        },
        listbox: {
          backgroundColor: '#6b4985', // Purple background for dropdown options
        },
        option: {
          backgroundColor: '#6b4985', // Purple background for options
          color: '#ffffff', // White text color
          '&[aria-selected="true"]': {
            backgroundColor: '#301934', // Darker purple for selected option
          },
          '&:hover': {
            backgroundColor: '#452650', // Darker purple on hover
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          color: '#ffffff', // White text for all items
          '&.Mui-selected': {
            backgroundColor: '#301934', // Dark purple for selected item
            '&:hover': {
              backgroundColor: '#452650', // Darker purple on hover
            },
          },
          '&:not(.Mui-selected)': {
            backgroundColor: '#6b4985', // Lighter purple for non-selected items
            '&:hover': {
              backgroundColor: '#7b5e97', // Slightly darker purple on hover for non-selected
            },
          },
        },
      },
    },
    // MuiList: {
    //   styleOverrides: {
    //     root: {
    //       backgroundColor: '#6b4985', // Lighter purple for non-selected items background
    //     },
    //   },
    // },
    MuiTableBody: {
      styleOverrides: {
        root: {
          '& .MuiTableRow-root:nth-of-type(odd)': {
            backgroundColor: '#2e2e2e' // Darker shade for alternate rows
          },
          '& .MuiTableRow-root:nth-of-type(even)': {
            backgroundColor: '#232323' // Base color
          },
          '& .MuiTableRow-root': {
            borderBottom: '1px solid #3c3c3c' // Border color between rows
          }
        }
      }
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiInputBase-input': {
            color: '#ffffff', // Set the input text color to white
          },
          '& .MuiInputLabel-root': {
            color: '#ffffff', // Set label color to white
          },
        }
      }
    },
    MuiTypography: {
      variants: [
        {
          props: { variant: 'heroSub' },
          style: {
            fontFamily: '"Mochiy Pop One", sans-serif',
            fontSize: '24px',
            display: 'block',
            margin: 6,
            letterSpacing: '-0.2px',
            color: '#ffffff', // Optional: set a specific color if needed
          }
        },
        {
          props: { variant: 'heroTitle' },
          style: {
            display: 'block',
            fontFamily: '"Mochiy Pop One", sans-serif',
            fontWeight: 700,
            fontSize: '64px',
            letterSpacing: '-1px',
            color: '#ffffff',
          }
        },
        {
          props: { variant: 'footerLink' },
          style: {
            fontFamily: '"Mochiy Pop One", sans-serif',
            fontSize: 14,
            textDecoration: 'none',
            color: '#dddddd',
            cursor: 'pointer',
            padding: 2,
          }
        },
      ],
    },
  },
});

export default theme;
