import React from 'react';
import { Box, Typography, Tooltip } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import CategoryIcon from './CategoryIcon';
import moment from 'moment';
import { ContentClassification, getClassificationIcon } from '../utils/ClassificationIconMapper';

interface KnowledgebaseArticleCardProps {
    title: string;
    content_synopsis: string;
    thumb_url: string;
    main_category: string;
    sub_category?: string;
    article_category?: string;
    updated_at: string;
    content_classification: string;
    slug: string; // Added slug for navigation
}

const KnowledgebaseArticleCard: React.FC<KnowledgebaseArticleCardProps> = ({
    title,
    content_synopsis,
    thumb_url,
    main_category,
    sub_category,
    article_category,
    updated_at,
    content_classification,
    slug, // Added slug prop for navigation
}) => {
    const navigate = useNavigate();
    const LeafIcon = getClassificationIcon(content_classification as ContentClassification); // Get the leaf based on the classification
    const formattedDate = moment(updated_at).format('MMMM Do, YYYY'); // Formatting date for display

    const handleCardClick = () => {
        navigate(`/learn${slug}`);
    };

    return (
        <Box
            onClick={handleCardClick} // Add click handler to navigate to /learn${slug}
            sx={{
                position: 'relative',
                height: '260px',
                backgroundColor: '#333',
                borderRadius: '8px',
                overflow: 'hidden',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                backgroundImage: `url(${thumb_url})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                transition: 'transform 0.3s ease-in-out',
                cursor: 'pointer',
                userSelect: 'none',
                '&:hover': {
                    transform: 'scale(1.05)',
                },
            }}
        >
            {/* Leaf Icon for Content Classification */}
            <Box
                sx={{
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    padding: '10px',
                }}
            >
                {LeafIcon && (
                    <Tooltip title={content_classification}>
                        <LeafIcon sx={{ fontSize: 48, color: '#fff' }} />
                    </Tooltip>
                )}
            </Box>

            {/* Article Title and Synopsis */}
            <Box
                sx={{
                    position: 'absolute',
                    bottom: 0,
                    width: '100%',
                    backgroundColor: 'rgba(0, 0, 0, 0.6)',
                    backdropFilter: 'blur(3px)',
                    padding: '16px',
                    color: '#fff',
                    textAlign: 'left',
                }}
            >
                <Typography variant="h6" sx={{
                    fontWeight: 'bold',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    pr: 3,
                }}>
                    {title}
                </Typography>
                <Box sx={{ display: 'flex', gap: '8px', zoom: 0.8 }}>
                    {main_category && <CategoryIcon category={main_category} nohover />}
                    {sub_category && <CategoryIcon category={sub_category} nohover />}
                    {article_category && <CategoryIcon category={article_category} nohover />}
                </Box>
                <Typography variant="caption" sx={{ display: 'block', mt: 1 }}>
                    Last updated: {formattedDate}
                </Typography>
            </Box>
        </Box>
    );
};

export default KnowledgebaseArticleCard;
