import { Grid, Box, Typography } from '@mui/material';
import LayoutContainer from '../components/LayoutContainer';
import { Image } from '@mui/icons-material';

const EveHowItWorksSection: React.FC = () => {
    const steps = [
        "Get expert advice on gameplay mechanics, ships, roles and careers via our learning portal.",
        "Access a range of tools to empower your experience via our services portal.",
        "Optionally, connect with your EVE character to see eligibility for finance or join our corporation."
    ];

    return (
        <Box component="section" id="howItWorks" sx={{ position: 'relative' }}>
            <Box sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                userSelect: 'none',
                pointerEvents: 'none',
                backgroundImage: "url('/assets/banners/banner_customise_low_contrast.jpg')",
                backgroundSize: 'cover',
                backgroundPosition: 'center',
            }}>
            </Box>
            <LayoutContainer>
                <Box sx={{ position: 'relative', pt: 6, pb: 6, textShadow: '2px 2px 2px #000;' }}>
                    <Typography variant="h3" sx={{ textAlign: 'center', mt: 2, mb: 4, color: 'primary.main' }}>
                        How It Works
                    </Typography>
                    <Grid container>
                        <Grid container item xs={12} md={7} spacing={2} sx={{ pl: { md: 12 } }}>
                            {steps.map((step, index) => (
                                <Grid item xs={12} key={index} sx={{
                                    display: 'flex',
                                    flexDirection: { xs: 'column', md: 'row' },
                                    alignItems: 'center',
                                    textAlign: { xs: 'center', md: 'left' },
                                    padding: 2,
                                    marginLeft: { md: index % 2 === 1 ? '7%' : '0%' } // Alternating margin for even-indexed items on non-xs screens
                                }}>
                                    <Box sx={{
                                        padding: 1,
                                        backgroundColor: '#fff',
                                        textAlign: 'center',
                                        width: '48px',
                                        height: '48px',
                                        borderRadius: '50%',
                                        marginBottom: { xs: 1, md: 0 },
                                        marginRight: { md: 2 }
                                    }}>
                                        <Typography variant="h6" component="span" sx={{ color: '#111', lineHeight: '48px', fontSize: '32px', textShadow: 'none' }}>
                                            {index + 1}
                                        </Typography>
                                    </Box>
                                    <Typography variant="body2" sx={{ padding: { xs: 2, md: 0 }, fontSize: '20px', maxWidth: { xs: '60%', md: '80%' } }}>
                                        {step}
                                    </Typography>
                                </Grid>
                            ))}
                        </Grid>
                        <Grid item xs={12} md={5}>
                            <Box component="img" src="/assets/graphics/integration_diagram.png" alt="Integration Diagram" sx={{
                                maxWidth: '100%',
                                height: '320px',
                                objectFit: 'contain',
                                display: { xs: 'none', md: 'block' }
                            }}></Box>
                        </Grid>
                    </Grid>
                    <Box sx={{}}>
                        <Typography variant="body1" sx={{ m: 5, mb: 0, textAlign: 'center', color: '#eee', padding: 4, backdropFilter: 'blur(10px)', backgroundColor: 'rgba(0, 0, 0, 0.3)' }}>
                            Help and guidance is available to all players without prejudice, some services and additional benefits require you to be a member of our in-game corporation, or access to your character information for finance. Our <a style={{ color: '#ff4', textDecoration: 'none'}} href="https://discord.gg/bloomies">discord server</a> is open to all.
                        </Typography>
                    </Box>

                </Box>

            </LayoutContainer>
        </Box>
    );
};

export default EveHowItWorksSection;
