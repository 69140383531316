import React, { ReactNode } from 'react';
import { FormControl, InputLabel, Select, MenuItem, SelectChangeEvent, ListItemIcon, ListItemText, Box } from '@mui/material';
import { useAuth } from '../context/AuthContext';
import { Public, Language, Category, Business, Person, DirectionsBoat, School, Work, Lightbulb, Book, LocalOffer, Gavel, Translate } from '@mui/icons-material'; // Icons from MUI

// Define an object that maps each KnowledgebaseType to an icon
const typeIcons: { [key: string]: ReactNode } = {
    SOLAR_SYSTEM: <Public />,
    REGION: <Language />,
    ITEM: <Category />,
    CLASS: <School />,
    CORPORATION: <Business />,
    PLAYER: <Person />,
    SHIP_HULL: <DirectionsBoat />,
    CAREER: <Work />,
    ROLE: <Work />,
    CONCEPT: <Lightbulb />,
    GUIDE: <Book />,
    PROMOTION: <LocalOffer />,
    POLICY: <Gavel />,
    TERM: <Translate />,
};

const KnowledgebaseTypeSelect: React.FC<{ value: string, onChange: (event: SelectChangeEvent<string>) => void }> = ({ value, onChange }) => {
    const knowledgebaseTypes = [
        'SOLAR_SYSTEM', 'REGION', 'ITEM', 'CLASS', 'CORPORATION', 'PLAYER',
        'SHIP_HULL', 'CAREER', 'ROLE', 'CONCEPT', 'GUIDE', 'PROMOTION', 'POLICY', 'TERM'
    ];

    return (
        <FormControl fullWidth margin="normal">
            <InputLabel>Type</InputLabel>
            <Select
                name="type"
                value={value}
                onChange={onChange}
                renderValue={(selected) => (
                    <Box sx={{ display: 'flex', alignItems: 'center', color: 'white' }}> {/* Ensure white text and icon */}
                        <ListItemIcon sx={{ color: 'white' }}> {/* Set icon color to white */}
                            {typeIcons[selected as string] || <Lightbulb />}
                        </ListItemIcon>
                        <ListItemText
                            primary={selected?.toString().replace('_', ' ')}
                            primaryTypographyProps={{ color: 'white' }} // Ensure text color is white
                        />
                    </Box>
                )}
                sx={{ color: 'white' }} // Ensure dropdown itself has white text
            >
                {knowledgebaseTypes.map((type) => (
                    <MenuItem key={type} value={type}>
                        <ListItemIcon>
                            {typeIcons[type] || <Lightbulb />} {/* Fallback icon if none is found */}
                        </ListItemIcon>
                        <ListItemText primary={type.replace('_', ' ')} />
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export default KnowledgebaseTypeSelect;
