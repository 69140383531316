import React, { JSXElementConstructor, ReactElement, ReactNode } from 'react';
import { Box, Link, Tooltip, Typography } from '@mui/material';
import ReactMarkdown from 'react-markdown';
import EtymologyHint from './EtymologyHint';
import InfoBox from '../components/InfoBox';
import { Info, Warning } from '@mui/icons-material';

// Function to escape special characters for use in a regex pattern
const escapeRegExp = (string: string) => {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // Escape special characters
};

// Function to replace etymology terms (and their aliases) with custom markdown identifiers
const replaceEtymologyTerms = (content: string, etymologyMap: { [key: string]: any }) => {
    let processedContent = content;

    // Loop through each etymology entry in the map and replace it in the content
    Object.keys(etymologyMap).forEach(term => {
        const etymology = etymologyMap[term];
        const aliases = (etymology.name + (etymology.aliases ?? '')).split(',').map((alias: string) => alias.trim()); // Split aliases by commas

        aliases.forEach((alias: string) => {
            const escapedAlias = escapeRegExp(alias);
            // Create a regex to match =alias= (case-insensitive)
            const regex = new RegExp(`=${escapedAlias}=`, 'gi');

            // Replace with a custom markdown identifier [@etymology:term]
            processedContent = processedContent.replace(regex, () => `[@etymology:${alias}]`);
        });
    });

    return processedContent;
};

const EtymologyRenderer = ({
    children,
    etymologies
}: {
    children: ReactNode[];
    etymologies: { [key: string]: any };
}) => {
    const renderText = (text: string, key: any): ReactNode => {
        const parts = text.split(/(\[@etymology:.*?\]|\[@hint:.*?\]|\[@warning:.*?\])/gi);

        return (
            <>
                {parts.map((part, subIndex) => {
                    if (part.startsWith('[@etymology:')) {
                        // Extract the etymology term
                        const term = part.slice(12, -1).toLowerCase();

                        let etymology: any = null;
                        etymologies.map((phrase: any) => {
                            if (phrase.name.toLowerCase() === term) {
                                etymology = phrase;
                            }
                            if (phrase.aliases) {
                                let aliases = phrase.aliases.split(',');
                                if (aliases.includes(term)) {
                                    etymology = phrase;
                                }
                            }
                        });

                        // If the etymology exists, return a tooltip, otherwise plain text
                        return etymology ? (
                            <EtymologyHint
                                key={subIndex}
                                name={etymology.name}
                                category={etymology.category}
                                description={etymology.short_description}
                                icon_url={etymology.icon_url}
                                moreInfoLink={`/learn${etymology.knowledgebaseItem?.slug}`}
                            />
                        ) : (
                            part // Return the original text if etymology not found
                        );
                    } else if (part.startsWith('[@hint:')) {
                        // Handle hint rendering
                        const hintContent = part.slice(7, -1);
                        return (
                            <InfoBox
                                key={subIndex}
                                icon={Info}
                                title="Hint"
                                text={hintContent}
                                backgroundColor="#4b4b4b" // Green for hints
                            />
                        );
                    } else if (part.startsWith('[@warning:')) {
                        // Handle warning rendering
                        const warningContent = part.slice(10, -1);
                        return (
                            <InfoBox
                                key={subIndex}
                                icon={Warning}
                                title="Warning"
                                text={warningContent}
                                backgroundColor="#6d5c35" // Orange for warnings
                            />
                        );
                    }
                    return part; // Return normal text for non-tag parts
                })}
            </>
        );
    };

    const renderNode = (node: ReactNode, key: any): ReactNode => {
        // If the node is a string, apply the text renderer
        if (typeof node === 'string') {
            return renderText(node, key);
        }

        // Ensure node is a valid ReactElement before applying cloneElement
        if (React.isValidElement(node) && node.props?.children) {
            const childArray = React.Children.toArray(node.props.children);
            return React.cloneElement(node as ReactElement<any>, {
                key,
                children: childArray.map((child, index) => renderNode(child, `${key}-${index}`))
            });
        }

        // If it's not a string or an element with children, return as is
        return node;
    };

    return (
        <>
            {React.Children.map(children, (child: ReactNode, index) => renderNode(child, index))}
        </>
    );
};

const EtymologyMarkdown = ({ content, etymologies }: { content: string; etymologies: { [key: string]: any } }) => {
    // Process the markdown content, replacing =terms= with the enriched placeholders
    const processedContent = replaceEtymologyTerms(content, etymologies);

    return (
        <ReactMarkdown
            components={{
                p({ children }) {
                    return <Typography variant="body1" component={'p'} sx={{ pt: 1.5 }}><EtymologyRenderer etymologies={etymologies} children={[children] as ReactNode[]} /></Typography>;
                },
                span({ children }) {
                    return <EtymologyRenderer etymologies={etymologies} children={[children] as ReactNode[]} />;
                },
                strong({ children }) {
                    return <strong style={{ paddingLeft: '2px', paddingRight: '2px' }}><EtymologyRenderer etymologies={etymologies} children={[children] as ReactNode[]} /></strong>;
                },
                ol({ children }) {
                    return <ol><EtymologyRenderer etymologies={etymologies} children={[children] as ReactNode[]} /></ol>;
                },
                ul({ children }) {
                    return <ul><EtymologyRenderer etymologies={etymologies} children={[children] as ReactNode[]} /></ul>;
                },
                li({ children }) {
                    return <li style={{ paddingTop: '4px' }}><EtymologyRenderer etymologies={etymologies} children={[children] as ReactNode[]} /></li>;
                },
                a({ children, href }) {
                    return <Link href={href}>
                        <EtymologyRenderer etymologies={etymologies} children={[children] as ReactNode[]} />
                    </Link>;
                },
                h3({ children }) {
                    return <Typography variant="h5" pt={2}>
                        <EtymologyRenderer etymologies={etymologies} children={[children] as ReactNode[]} />
                    </Typography>;
                },
                h2({ children }) {
                    return <Typography variant="h4" pt={2}>
                        <EtymologyRenderer etymologies={etymologies} children={[children] as ReactNode[]} />
                    </Typography>;
                },
                h1({ children }) {
                    return <Typography variant="h3" pt={2}>
                        <EtymologyRenderer etymologies={etymologies} children={[children] as ReactNode[]} />
                    </Typography>;
                },
                h4({ children }) {
                    return <Typography variant="h6" pt={2}>
                        <EtymologyRenderer etymologies={etymologies} children={[children] as ReactNode[]} />
                    </Typography>;
                },
                img({ children, src, alt }) {
                    return <Box alignItems={'center'} sx={{textAlign: 'center', m: 1, position: 'relative', overflow: 'hidden', userSelect: 'none' }}>
                        <img style={{ display: 'inline-block', maxWidth: '100%', borderRadius: '4px', boxShadow: '0px 4px 10px rgba(0,0,0,0.5);' }} src={src} />
                        {/* {alt &&  <Box sx={{ position: 'absolute', left: 0, bottom: 0, right: 0, p: 1, backgroundColor: 'rgba(0,0,0,0.5)', backdropFilter: 'blur(3px)', textAlign: 'left'}}>
                            <Typography variant="body1">{alt}</Typography></Box>} */}
                       
                        </Box>;
                }
            }}
        >
            {processedContent}
        </ReactMarkdown>
    );
};


export default EtymologyMarkdown;
