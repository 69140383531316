// src/pages/PageCommunity.tsx
import React, { useEffect, useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import SubrouteLayout from '../components/SubrouteLayout';
import SubrouteNav from '../components/SubrouteNav';
import PagePartnerBecomeACreator from './PagePartnerBecomeACreator';
import PagePartnerPlatformGuidelines from './PagePartnerPlatformGuidelines';
import { Box, CircularProgress, Grid, Link, Typography } from '@mui/material';
import { useAuth } from '../context/AuthContext';
import LayoutContainer from '../components/LayoutContainer';
import ThemeBackground from '../components/ThemeBackground';
import { ProfileTheme } from '../types/ProfileTheme';
import { useNavigate } from 'react-router';
import { LinkSharp, Lock, Notifications, Settings, Visibility } from '@mui/icons-material';
import PageSettingsOverview from './PageSettingsOverview';
import PageSettingsConnections from './PageSettingsConnections';

const version = process.env.VERSION ?? 'Local Development';

const theme: ProfileTheme = {
    avatarUrl: '/assets/avatar_example.png',
    backgroundUrl: '/assets/theme/tech/background.jpg',
    overlayUrls: [],
    horizontalRuleUrl: '/assets/theme/tech/seperator.jpg',
    backgroundColor: '#110f0d',
    overlayStyles: [],
    fontStyles: {
        title: { fontFamily: '"Jersey 20"', fontSize: '80px', lineHeight: '80px', fontWeight: 400, fontStyle: 'normal', color: '#fff' },
        subheading: { fontFamily: '"Jersey 10"', fontSize: '40px', fontWeight: 400, lineHeight: '48px', fontStyle: 'normal', color: '#fff' },
        text: { fontFamily: '"Jersey 10"', fontSize: '24px', fontWeight: 400, lineHeight: '30px', fontStyle: 'normal', color: '#fff' },
        para: { fontFamily: '"DM Sans"', fontSize: '16px', color: '#999' }
    },
    tabStyle: {
        backgroundColor: 'transparent', color: '#fff', padding: '10px 20px',
    },
    containerStyle: { backgroundColor: '#1b1c1e' },
    globalStyles: {
        transition: 'opacity 2s',
        opacity: 0,
    },
};

const PageSettings: React.FC = () => {
    const { user } = useAuth();
    const [loading, setLoading] = useState(user == null);
    const navigate = useNavigate();

    useEffect(() => {
        const timer = setTimeout(() => {
            if (!user) {
                navigate('/auth');
            } else {
                setLoading(false);
            }
        }, 0);

        return () => clearTimeout(timer);
    }, [user, history]);

    let modifiedTheme = { ...theme, globalStyles: { ...theme.globalStyles, opacity: loading ? 0 : 1 } };

    // Array of welcome messages
    const welcomeMessages = [
        'Welcome back,',
        'We\'ve missed you,',
        'Nice to see you again,',
        'Good to see you,'
    ];

    // Select a random welcome message
    const randomIndex = Math.floor(Math.random() * welcomeMessages.length);
    const randomWelcomeMessage = welcomeMessages[randomIndex];

    const navItems = [
        { icon: <Visibility/>, label: 'Overview', path: '/settings' },
        // { icon: <Lock/>, label: 'Privacy Settings', path: '/settings/privacy' },
        // { icon: <Notifications/>, label: 'Notifications', path: '/settings/notifications' },
        { icon: <LinkSharp/>, label: 'Connections', path: '/settings/connections' },
    ];

    const header = <Grid container spacing={2}  p={1} pb={2} mb={1} sx={{ filter: 'drop-shadow(0px 0px 8px #000)', background: 'linear-gradient(42deg, #0000003d, transparent)' }}>
        <Grid item xs={2} sx={{ textAlign:'right'}}>
        <Settings sx={{ fontSize: '3em', lineHeight: '48px', color: '#fff' }} />
        </Grid>
       <Grid item xs={10} sx={{ textAlign:'left'}}>
       <Typography variant='h4' sx={{lineHeight: '48px'}}>Settings</Typography>
       </Grid>
      
    </Grid>

    return (

        <Box component="section" sx={{ background: theme.backgroundColor, color: theme.fontStyles.text.color, position: 'relative', minHeight: '100vh' }}>
            <ThemeBackground theme={modifiedTheme} />
            <Box sx={{ position: 'relative', filter: 'drop-shadow(0px 5px 12px rgba(0,0,0,0.9))', }}>
                <LayoutContainer>
                    {user != null && <>
                        <SubrouteLayout wide sidebar={<>
                            <SubrouteNav routes={navItems} heading={header} />
                            <Box sx={{ position: 'relative', mt: 2, p: 2, backgroundColor: '#000',  borderRadius: '12px', textAlign: 'center', lineHeight: ' 32px', opacity: 0.25, }}>
                            <Typography variant='body2' >App Version</Typography>
                            <Typography variant='body2' >{version}</Typography>
                            
                            </Box>
                          
                        </>}>
                            <Routes>
                                <Route path="/" element={<PageSettingsOverview/>} />
                                <Route path="/connections" element={<PageSettingsConnections />} />
                                <Route path="become_a_creator" element={<PagePartnerBecomeACreator />} />
                                <Route path="platform_guidelines" element={<PagePartnerPlatformGuidelines />} />
                            </Routes>
                        </SubrouteLayout></>}
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'column',
                        maxWidth: '800px',
                        margin: '0 auto',
                        textAlign: 'center',
                        textShadow: '0px 2px 2px #000'
                    }}>
                        {loading && (
                            <CircularProgress />
                        )}
                    </Box>
                </LayoutContainer>
            </Box>
        </Box>
    );
};

export default PageSettings;
