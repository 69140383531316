import { Box, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import LayoutContainer from '../components/LayoutContainer';

// Video background styling
const Section = styled.section`
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
`;

const VideoBackground = styled.video`
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
`;

// Halftone effect overlay
const HalftoneOverlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: repeating-linear-gradient(
    45deg,
    rgba(0, 0, 0, 0.2),
    rgba(0, 0, 0, 0.2) 0.1em,
    rgba(0, 0, 0, 0.5) 0.1em,
    rgba(0, 0, 0, 0.4) 0.3em
  );
  z-index: 0;
  pointer-events: none;
`;


const fadeIn = keyframes`
  0% { opacity: 0; }
  100% { opacity: 1; }
`;

const fadeInOut = keyframes`
  0% { opacity: 0; transform: scale(0.8); letter-spacing: -1px; }
  20% { opacity: 1; transform: scale(1); letter-spacing: 0px; }
  60% { opacity: 0.8; transform: scale(1.1); letter-spacing: 2px; }
  100% { opacity: 0; transform: scale(1.2); letter-spacing: 4px; }
`;

// Enhanced FlashText with scaling and rotation
const FlashText = styled.div<{ delay: number }>`
  position: absolute;
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  left: 0px;
  right: 0px;
  top: 50%;
  margin-top: -32px;
  line-height: 64px;
  text-transform: uppercase;
  font-size: 64px;
  font-weight: bold;
  color: white;
  opacity: 0;
  font-family: "Dosis", sans-serif;
  animation: ${fadeInOut} 2.2s linear forwards;
  text-shadow: 0px 5px 15px #000;
  animation-delay: ${({ delay }) => delay}s;
  transform-origin: center;
`;

const FinalTextContainer = styled.div`
  position: absolute;
  text-align: center;
  font-family: '"Rubik", Arial, sans-serif';
  color: white;
  left: 0px;
  right: 0px;
  bottom: 0px;
  top: 0px;
  background-color: rgba(0,0,0,0.5);
  opacity: 0;
  animation: ${fadeIn} 1.1s ease-in forwards;
  animation-delay: 4.7s;
  text-shadow: 0px 2px 0px #111, 0px 4px 20px #000;
`;

const Title = styled.h1`
  font-size: 5rem;
  margin-bottom: 20px;
`;

const Subheading = styled.h2`
  font-size: 2rem;
  margin-bottom: 20px;
`;

const CorpIcon = styled.img`
  margin-bottom: 10px;
  image-rendering: crisp-edges;
`;

const LearnMoreButton = styled.button`
  background-color: #6d6d6d;
  color: white;
  padding: 10px 20px;
  border: none;
  font-size: 1.2rem;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s;
  box-shadow: 0px 3px 0px #333333, 0px 4px 20px #000;
  margin-bottom: 3px;

  &:hover {
    background-color: #888888;
  }

  &:active {
    margin-top: 1px;
    margin-bottom: 2px;
    box-shadow: 0px 1px 0px #333333, 0px 4px 20px #000;
    background-color: #444444;
  }
`;

const GradientOverlay = styled.div<{ opacity: number }>`
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 350px;
  z-index: 1;
  background: linear-gradient(to top, #110f12, #23202500);
  transition: opacity 0.5s ease;
  opacity: ${({ opacity }) => opacity};
  pointer-events: none;
`;

// Main component
const HeroJoinUsSection: React.FC = () => {
  const [showFinalText, setShowFinalText] = useState(false);
  const [gradientOpacity, setGradientOpacity] = useState(window.scrollX != 0 ? 1 : 0);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowFinalText(true);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      const newOpacity = scrollTop > 50 ? 1 : 0; // Adjust the threshold as needed
      setGradientOpacity(newOpacity);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <Section style={{ marginTop: 0, top: 0, position: 'relative', display: 'block', userSelect: 'none' }}>

      <VideoBackground autoPlay muted loop>
        <source src="https://cdn.everbloom.cloud/eve/videos/site_bg_slim.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </VideoBackground>


      <HalftoneOverlay />
      <GradientOverlay opacity={gradientOpacity} />
      
      <LayoutContainer>
        <FlashText delay={0}>
          be kind
        </FlashText>
        <FlashText delay={1.8} >
          be empathetic
        </FlashText>
        <FlashText delay={3.5} >
          pursue mutual Success
        </FlashText>

        {showFinalText && (
          <FinalTextContainer>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              height={'100%'}
              sx={{
                textAlign: 'center',
              }}
            >
              <CorpIcon
                src="https://cdn.everbloom.cloud/assets/icons/corp.png"
                width={128}
                alt="The Everbloom Corporation"
              />
              <Typography variant="h3" fontSize={40} pb={2} align="center" textAlign={'center'}>
                The Everbloom Corporation
              </Typography>
              {/* <Typography variant="h4" pb={4} align="center" textAlign={'center'}>
                Thrive on your terms
              </Typography> */}
              <Typography align="center" p={3} pt={1} pb={4} fontSize={20} style={{ maxWidth: '500px' }}>
                Our mission is to become the best corporation to join if you are looking to jumpstart your EVE journey.
              </Typography>
              <LearnMoreButton><Typography variant="h6">Learn More</Typography></LearnMoreButton>
            </Box>
            <Box display={"block"} sx={{ padding: 2, textAlign: 'center', opacity: 1 - gradientOpacity, bottom: 25, left: 0, right: 0, position: 'absolute' }}>
              <Typography variant="body1" align='center' sx={{ opacity: 0.5 }}>The Everbloom Company is a fictional player corporation within the EVE Online universe.</Typography>

            </Box>
          </FinalTextContainer>
        )}
      </LayoutContainer>


    </Section>
  );
};

export default HeroJoinUsSection;
