import React from 'react';
import { Box, Typography, Button, Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import LayoutContainer from '../components/LayoutContainer';

const MemberServicesPage: React.FC = () => {
    const navigate = useNavigate();

    return (
        <LayoutContainer>
            <Box sx={{ padding: 3, pt: 10 }}>
                <Typography variant="h3" gutterBottom>
                    Everbloom Member Services
                </Typography>

                <Grid container spacing={3} pt={3}>
                    <Grid item xs={12} md={6}>
                        <Box sx={{ padding: 2, backgroundColor: '#333', color: '#fff', borderRadius: '8px' }}>
                            <Typography variant="h5">Everbloom Audit</Typography>
                            <Typography variant="body1" sx={{ mt: 2 }}>
                                Complete a full audit of your EVE Online character for internal evaluation.
                            </Typography>
                            <Button
                                variant="contained"
                                sx={{ mt: 2 }}
                                onClick={() => navigate('/services/audit')}
                            >
                                Start Audit
                            </Button>
                        </Box>
                    </Grid>

                    {/* Other services can be listed here */}
                </Grid>
            </Box>
        </LayoutContainer>
    );
};

export default MemberServicesPage;
