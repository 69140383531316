import {
    School,
    Insights,
    Gavel,
    Work,
    Lightbulb,
    HelpOutline,
    MoreHoriz,
  } from '@mui/icons-material'; // Import Material UI icons
  
  import { SvgIconComponent } from '@mui/icons-material';
  
  // Enum definition
  export enum ContentClassification {
    BEGINNER = 'BEGINNER',
    INTERMEDIATE = 'INTERMEDIATE',
    ADVANCED = 'ADVANCED',
    OPINION = 'OPINION',
    WORK_IN_PROGRESS = 'WORK_IN_PROGRESS',
    NONE = 'NONE',
    OTHER = 'OTHER',
  }
  
  // Mapping of content classification to Material UI icons
  const classificationIconMap: { [key in ContentClassification]: SvgIconComponent } = {
    [ContentClassification.BEGINNER]: School,
    [ContentClassification.INTERMEDIATE]: Insights,
    [ContentClassification.ADVANCED]: Work,
    [ContentClassification.OPINION]: Gavel,
    [ContentClassification.WORK_IN_PROGRESS]: Lightbulb,
    [ContentClassification.NONE]: HelpOutline,
    [ContentClassification.OTHER]: MoreHoriz,
  };
  
  // Function to get the icon component based on the classification
  export const getClassificationIcon = (classification: ContentClassification): SvgIconComponent => {
    return classificationIconMap[classification] || HelpOutline;
  };
  