import React, { useState, useEffect } from 'react';
import { Autocomplete, TextField, FormControl, CircularProgress } from '@mui/material';
import { debounce } from 'lodash';

const KnowledgebaseAutocomplete: React.FC<{ onSelect: (id: number | null) => void }> = ({ onSelect }) => {
    const [knowledgebaseItems, setKnowledgebaseItems] = useState<any[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [query, setQuery] = useState<string>('');
    const [selectedItem, setSelectedItem] = useState<any | null>(null);

    // Debounced API call to fetch search results
    const fetchKnowledgebaseItems = debounce(async (searchQuery: string) => {
        if (searchQuery) {
            setLoading(true);
            try {
                const response = await fetch(`/api/kb/search?query=${encodeURIComponent(searchQuery)}`);
                const data = await response.json();
                setKnowledgebaseItems(data);
            } catch (error) {
                console.error('Error fetching knowledgebase items:', error);
            } finally {
                setLoading(false);
            }
        } else {
            setKnowledgebaseItems([]); // Clear options if the query is empty
        }
    }, 500); // Debounce for 500ms

    // Trigger API call on query change
    useEffect(() => {
        fetchKnowledgebaseItems(query);
    }, [query]);

    return (
        <FormControl fullWidth margin="normal">
            <Autocomplete
                options={knowledgebaseItems}
                getOptionLabel={(option: any) => option.title || ''}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                onInputChange={(event, newInputValue) => {
                    setQuery(newInputValue); // Update the search query as the user types
                }}
                onChange={(event, newValue) => {
                    setSelectedItem(newValue);
                    onSelect(newValue ? newValue.id : null); // Pass selected item's ID back to the parent
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Search Knowledgebase Items"
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <>
                                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                    {params.InputProps.endAdornment}
                                </>
                            ),
                        }}
                    />
                )}
            />
        </FormControl>
    );
};

export default KnowledgebaseAutocomplete;
