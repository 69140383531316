import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, CircularProgress, Typography, Table, TableBody, TableCell, TableHead, TableRow, TableContainer, Paper } from '@mui/material';
import LayoutContainer from '../components/LayoutContainer'; // Assuming you have a LayoutContainer component

interface QuizQuestion {
    id: number;
    level: string;
    topic: string;
    question: string;
}

const PageQuizQuestions: React.FC = () => {
    const [quizQuestions, setQuizQuestions] = useState<QuizQuestion[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const navigate = useNavigate();

    // Fetch the list of quiz questions from the API
    useEffect(() => {
        const fetchQuizQuestions = async () => {
            try {
                const response = await fetch('/api/quiz/questions');
                if (!response.ok) {
                    throw new Error('Failed to fetch quiz questions');
                }
                const data = await response.json();
                setQuizQuestions(data);
            } catch (err) {
                setError('Error fetching quiz questions.');
            } finally {
                setLoading(false);
            }
        };

        fetchQuizQuestions();
    }, []);

    const handleCreateNew = () => {
        navigate('/quiz/questions/new');
    };

    const handleEditQuestion = (id: number) => {
        navigate(`/quiz/questions/edit/${id}`);
    };

    if (loading) {
        return (
            <Box sx={{ textAlign: 'center', marginTop: '20vh' }}>
                <CircularProgress />
            </Box>
        );
    }

    if (error) {
        return (
            <Box sx={{ textAlign: 'center', marginTop: '20vh' }}>
                <Typography variant="h6" color="error">
                    {error}
                </Typography>
            </Box>
        );
    }

    return (
        <Box sx={{ padding: 4 }}>
            <LayoutContainer>
                <Typography variant="h4" sx={{ mb: 4 }}>
                    Quiz Questions
                </Typography>

                <Button variant="contained" color="primary" onClick={handleCreateNew} sx={{ mb: 2 }}>
                    Create New Question
                </Button>

                {quizQuestions.length > 0 ? (
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>ID</TableCell>
                                    <TableCell>Level</TableCell>
                                    <TableCell>Topic</TableCell>
                                    <TableCell>Question</TableCell>
                                    <TableCell>Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {quizQuestions.map((question) => (
                                    <TableRow key={question.id}>
                                        <TableCell>{question.id}</TableCell>
                                        <TableCell>{question.level}</TableCell>
                                        <TableCell>{question.topic}</TableCell>
                                        <TableCell>{question.question}</TableCell>
                                        <TableCell>
                                            <Button
                                                variant="outlined"
                                                color="primary"
                                                onClick={() => handleEditQuestion(question.id)}
                                            >
                                                Edit
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                ) : (
                    <Typography>No quiz questions available.</Typography>
                )}
            </LayoutContainer>
        </Box>
    );
};

export default PageQuizQuestions;
